import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {gsap} from "gsap";
import {hover, textMid, title} from "../../styles/globalStyleVars";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    let component = useRef();

    useEffect(() => {
        if (component && window.innerWidth > 768) {
            ScrollTrigger.create({
                trigger: ".equipment-detail__sidebar",
                pin: ".equipment-detail__sidebar",
                start: "top 100",
                end: "+=500",
            });
        }

    }, [data])

    return (
        <StyledComponent className='equipment-detail pt-100 pb-100'>
            <Container>
                <Row>
                    <Col sm={2} className="equipment-detail__sidebar">
                        <p>Share</p>
                        <ul>
                            <li><img src="/images/static/fb.svg" alt=""/><FacebookShareButton
                                url={window.location.href}/></li>
                            <li><img src="/images/static/in.svg" alt=""/><LinkedinShareButton
                                url={window.location.href}/></li>
                            <li><img src="/images/static/x.svg" alt=""/> <TwitterShareButton
                                url={window.location.href}/></li>
                        </ul>
                    </Col>

                    <Col sm={{span: 9, offset: 1}} className={"equipment-detail__desc"}>
                        <div className="equipment-detail__desc__head">
                            <p>Blog</p>
                            <p>23 August 2023</p>
                        </div>
                        {ReactHtmlParser(data?.data?.body)}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .equipment-detail__sidebar {
    p {
      font-size: 12px;
      line-height: 16px;
      color: #b7b4b0;
      margin-bottom: 6px;
    }

    ul {
      li {

        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 1px solid ${hover};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        background-color: transparent;
        transition: background-color .5s ease;
        position: relative;

        img {
          height: 10px;
          transition: .3s ease;
        }

        &:hover {
          background-color: ${hover};

          img {
            filter: invert(95%) sepia(100%) saturate(44%) hue-rotate(256deg) brightness(234%) contrast(162%);
          }
        }

        button {
          position: absolute;
          inset: 0;
          z-index: 2;
          cursor: pointer;
        }
      }
    }
  }

  .equipment-detail__desc {
    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      p {
        font-size: 12px;
        line-height: 16px;
        color: #b7b4b0;
        margin-bottom: 6px !important;
      }

    }

    h1 {
      font-size: 48px;
      line-height: 58px;
      font-family: ${title};
      margin-bottom: 60px;
      border-bottom: 1px solid #B7B4B0;
      padding-bottom: 60px;
    }

    p {
      margin-bottom: 20px;
    }

    img {
      margin-bottom: 40px;
      margin-top: 30px;
    }

    ul {
      margin-top: 60px;
      margin-bottom: 60px;

      li {
        position: relative;
        counter-increment: list-counter;
        padding-left: 40px;
        padding-bottom: 40px;
        margin-bottom: 40px;

        span {
          font-size: 20px;
          font-weight: 500;
          font-family: ${textMid};
          line-height: 24px;
          display: block;
          margin-bottom: 10px;
        }

        &:not(:nth-last-of-type(1)) {
          border-bottom: 1px solid rgba(183, 180, 176, 0.30);
        }

        &:before {
          content: counter(list-counter, upper-alpha);
          margin-right: 5px;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background-color: #3E3937;
          position: absolute;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;
          top: 2px;
          color: #FFF;
          padding-top: 4px;
        }

        h4 {
          font-size: 18px;
          line-height: 22px;
          font-weight: 500;
          font-family: ${textMid};
          margin-bottom: 20px;
        }

        p {
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
        }

        &:nth-last-of-type(1) {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }

      p {
        margin-top: 40px;
      }
    }
  }

  @media (max-width: 768px) {

    .equipment-detail__sidebar {
      min-width: 100%;

      ul {
        display: flex;

        li {
          a {
            margin: 0 10px 0 0;
          }
        }
      }
    }

    .equipment-detail__desc {
      min-width: 100%;
      margin: 40px 0 0 0;

      h1 {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 40px;
        padding-bottom: 40px;
      }
    }
  }
`;


export default MyComponent;
