import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {hover, text, textMid, title, Transition, White} from "../styles/globalStyleVars";
import {Accordion, Col, Container, Row} from 'react-bootstrap';
import Button from "./Button";
import {ScrollToPlugin} from "gsap/dist/ScrollToPlugin";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {Link, useLocation} from "react-router-dom";
// import social1 from '../public/images/static/fb.svg';
// import social2 from '../public/images/static/twitter.svg';
// import social3 from '../public/images/static/insta.svg';
// import social4 from '../public/images/static/youtube.svg';

const MyComponent = ({leftImage, rightImage}) => {
    gsap.registerPlugin(ScrollToPlugin);
    gsap.registerPlugin(CSSPlugin);
    let tl = new TimelineLite();
    let tl2 = new TimelineLite();
    const location = useLocation()
    // video mute/unmute
    // useEffect(() => {
    //     document.querySelector('.controller').addEventListener('click', function () {
    //         this.classList.toggle('sound')
    //         if (this.classList.contains('sound')) {
    //             document.querySelector('.banner__video video').muted = true;
    //         } else {
    //             document.querySelector('.banner__video video').muted = false;
    //         }
    //     })
    //
    // }, [])


    // menu toggle action
    useEffect(() => {

        document.querySelector('.menu-desktop__hamburger__lines').addEventListener('click', function () {
            const getLis = document.querySelectorAll('.hover-item');
            const getimgs = document.querySelectorAll('.left li');
            const rightWrapper = document.querySelectorAll('.right');
            const getRightImg = document.querySelectorAll('.right li');
            const wrapper = document.querySelector('.left')

            if (!document.querySelector('.menu-desktop').classList.contains('menu-open')) {
                tl.to('.menu-items', {
                    duration: 0,
                    display: 'block'
                }).to('.menu-items', {
                    duration: .5,
                    opacity: 1
                }).to('.accordion', {
                    y: 0,
                    opacity: 1
                }, '-=.5').to(wrapper, .8, {
                    scrollTo: {
                        y: getimgs[3].offsetTop - 80,
                        autoKill: true
                    },
                    ease: 'Power1.easeOut'
                }, '-=.5').to(rightWrapper, .8, {
                    scrollTo: {
                        y: getRightImg[3].offsetTop,
                        autoKill: true
                    },
                    ease: 'Power1.easeOut'
                }, '-=.8')
                document.querySelector('.menu-desktop').classList.add('menu-open')
                document.body.classList.add('menu-action')
            } else {
                tl2.to('.accordion', {
                    duration: .2,
                    y: 100,
                    opacity: 0
                }).to('.menu-items', {
                    duration: .2,
                    opacity: 0
                }).to(wrapper, .3, {
                    scrollTo: {
                        y: getimgs && getimgs[2].offsetTop - 80,
                        autoKill: true
                    },
                }, '-=.4').to(rightWrapper, .3, {
                    scrollTo: {
                        y: getRightImg[2].offsetTop,
                        autoKill: true
                    },
                }, '-=.4').to('.menu-items', {
                    duration: 0,
                    display: 'none',
                    opacity: 0
                })
                document.querySelector('.menu-desktop').classList.remove('menu-open')
                document.body.classList.remove('menu-action')
            }


        });


        // close
        window.addEventListener('click', e => {
            const getLis = document.querySelectorAll('.hover-item');
            const getimgs = document.querySelectorAll('.left li');
            const rightWrapper = document.querySelectorAll('.right');
            const getRightImg = document.querySelectorAll('.right li');
            const wrapper = document.querySelector('.left')
            if (!e.target.matches('.menu-desktop__hamburger__lines,.menu-desktop__hamburger__lines__inner,.menu-desktop__hamburger__lines p:nth-of-type(1),.menu-desktop__hamburger__lines__inner .line,.accordion-item,.accordion-header,.accordion-header button')) {
                tl2.to('.accordion', {
                    duration: .2,
                    y: 100,
                    opacity: 0
                }).to('.menu-items', {
                    duration: .2,
                    opacity: 0
                }).to(wrapper, .3, {
                    scrollTo: {
                        y: getimgs && getimgs[2].offsetTop - 80,
                        autoKill: true
                    },
                }, '-=.4').to(rightWrapper, .3, {
                    scrollTo: {
                        y: getRightImg[2].offsetTop,
                        autoKill: true
                    },
                }, '-=.4').to('.menu-items', {
                    duration: 0,
                    display: 'none',
                    opacity: 0
                })
                document.querySelector('.menu-desktop').classList.remove('menu-open')
                document.body.classList.remove('menu-action')
            }

        })

    }, [])

    // useEffect(() => {


    // document.querySelector('.menu-desktop__hamburger__lines').addEventListener('click', function () {
    // if (document.querySelector('.menu-desktop').classList.contains('menu-open')) {
    //     const getLis = document.querySelectorAll('.hover-item');
    //     const getimgs = document.querySelectorAll('.left li');
    //     const rightWrapper = document.querySelectorAll('.right');
    //     const getRightImg = document.querySelectorAll('.right li');
    //     const wrapper = document.querySelector('.left')
    //
    //     console.log('clicked')

    // tl2.to('.accordion', {
    //     duration: .2,
    //     y: 100,
    //     opacity: 0
    // }).to('.menu-items', {
    //     duration: .2,
    //     opacity: 0
    // }).to(wrapper, .3, {
    //     scrollTo: {
    //         y: getimgs && getimgs[2].offsetTop - 80,
    //         autoKill: true
    //     },
    // }, '-=.4').to(rightWrapper, .3, {
    //     scrollTo: {
    //         y: getRightImg[2].offsetTop,
    //         autoKill: true
    //     },
    // }, '-=.4').to('.menu-items', {
    //     duration: 0,
    //     display: 'none',
    //     opacity: 0
    // })
    // document.querySelector('.menu-desktop').classList.remove('menu-open')
    //             // document.body.classList.remove('menu-action')
    //         }
    //     });
    //
    // }, [])


    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains("scroll-down")) {
            document.body.classList.remove("scroll-down");
        }
    });
    useEffect(() => {
        const body = document.body;
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";
        let lastScroll = 0;
        let howMuchScroll = 50;
        // if (window.screen.width < 991) {
        //     howMuchScroll = 100;
        // } else {
        //     howMuchScroll = 150;
        // }

        window.addEventListener("scroll", () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                return;
            }
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (
                currentScroll < lastScroll &&
                body.classList.contains(scrollDown)
            ) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }
            lastScroll = currentScroll;
        });
    }, []);

    // offset get
    const [offset, setOffset] = useState(90)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])


    // menu hover effect
    // useEffect(() => {
    //     const getLis = document.querySelectorAll('.hover-item');
    //     const getimgs = document.querySelectorAll('.left li');
    //     const rightWrapper = document.querySelectorAll('.right');
    //     const getRightImg = document.querySelectorAll('.right li');
    //     const wrapper = document.querySelector('.left')
    //     getLis && getLis.forEach((e, i) => {
    //         e.addEventListener('mouseover', (b) => {
    //             console.log('index', i)
    //             gsap.to(wrapper, .8, {
    //                 scrollTo: {
    //                     y: getimgs && getimgs[i].offsetTop - 80,
    //                     autoKill: true
    //                 },
    //                 ease: 'Power1.easeOut'
    //             });
    //
    //             gsap.to(rightWrapper, .8, {
    //                 scrollTo: {
    //                     y: getRightImg[i + 1].offsetTop,
    //                     // y: -500,
    //                     autoKill: true
    //                 },
    //                 ease: 'Power1.easeOut'
    //             });
    //         })
    //     })
    // },[leftImage, rightImage, offset])

    useEffect(() => {
        const getLis = document.querySelectorAll('.hover-item');
        const getimgs = document.querySelectorAll('.left li');
        const rightWrapper = document.querySelector('.right');
        const getRightImg = document.querySelectorAll('.right li');
        const wrapper = document.querySelector('.left');

        if (getLis && getimgs && rightWrapper && getRightImg && wrapper) {
            getLis.forEach((e, i) => {
                e.addEventListener('mouseover', () => {
                    if (getimgs[i] && getRightImg[i + 1]) { // Check if elements exist before accessing properties
                        gsap.to(wrapper, .8, {
                            scrollTo: {
                                y: getimgs[i].offsetTop - 80,
                                autoKill: true
                            },
                            ease: 'Power1.easeOut'
                        });

                        gsap.to(rightWrapper, .8, {
                            scrollTo: {
                                y: getRightImg[i + 1].offsetTop,
                                autoKill: true
                            },
                            ease: 'Power1.easeOut'
                        });
                    }
                });
            });
        }
    }, [leftImage, rightImage]);


    return (

        <>
            {/*desktop/mobile menu bar*/}
            <StyledDesktopMenu className={'menu-desktop'}>
                <Container className={'pr-0'}>
                    <Row>
                        <Col sm={4} className={'menu-desktop__hamburger'}>
                            <div className="menu-desktop__hamburger__lines">
                                <div className="menu-desktop__hamburger__lines__inner">
                                    <div className="line"/>
                                    <div className="line"/>
                                    <div className="line"/>

                                    {/*<svg width="17.414" height="17.414" viewBox="0 0 17.414 17.414">*/}
                                    {/*    <g id="Group_13312" data-name="Group 13312"*/}
                                    {/*       transform="translate(-97.293 -31.293)">*/}
                                    {/*        <line id="_1" data-name="1" x2="16" y2="16" transform="translate(98 32)"*/}
                                    {/*              fill="none" stroke="#191818" stroke-linecap="round"*/}
                                    {/*              stroke-width="1"/>*/}
                                    {/*        <line id="_1-2" data-name="1" x1="16" y2="16"*/}
                                    {/*              transform="translate(98 32)"*/}
                                    {/*              fill="none" stroke="#191818" stroke-linecap="round"*/}
                                    {/*              stroke-width="1"/>*/}
                                    {/*    </g>*/}
                                    {/*</svg>*/}

                                </div>
                                <p>Menu</p>
                                <p>Close</p>


                            </div>


                            <Link className={location.pathname.startsWith('/products') ? 'active' : ''}
                                  to={'/products'}>Products</Link>

                        </Col>

                        <Col sm={4} className={'menu-desktop__logo d-flex justify-content-center'}>
                            <Link to={'/'}>
                                <img src={'/images/static/logo.svg'} alt=""/>
                            </Link>
                        </Col>

                        <Col sm={4} className={'menu-desktop__button pr-0'}>
                            <Link className={location.pathname === "/contact" ? 'active' : ''}
                                  to={'/contact'}>Contact</Link>
                            <Link to={'/search'}>

                                <svg id="Animation_-_Search" data-name="Animation - Search"
                                     xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="_12" data-name="12" transform="translate(6.04 6.04)">
                                        <path id="Path_1903" data-name="Path 1903"
                                              d="M14.768,13.847l-1.835-1.829a6.174,6.174,0,1,0-.914.914l1.829,1.835a.651.651,0,1,0,.921-.921ZM3.283,8.147A4.864,4.864,0,1,1,8.147,13.01,4.864,4.864,0,0,1,3.283,8.147Z"
                                              transform="translate(0 0)" fill="#fff"/>
                                    </g>
                                    <g id="Rectangle_6209" data-name="Rectangle 6209" fill="none" stroke="#fff"
                                       stroke-width="1" opacity="0.3">
                                        <rect width="30" height="30" rx="15" stroke="none"/>
                                        <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none"/>
                                    </g>
                                    <g id="Rectangle_6231" data-name="Rectangle 6231"
                                       transform="translate(30 30) rotate(180)" fill="none" stroke="#fff"
                                       stroke-width="1" stroke-dasharray="0 100">
                                        <rect width="30" height="30" rx="15" stroke="none"/>
                                        <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none"/>
                                    </g>
                                </svg>


                            </Link>
                        </Col>
                    </Row>
                </Container>

            </StyledDesktopMenu>

            {/*desktop/mobile menu item*/}
            <StyledMenuItem className={'menu-items'}>
                <div className="d-flex ">
                    <div className="menu-items__lists col-sm-5" style={{paddingLeft: offset + 15 + 'px'}}>
                        <Accordion>
                            <ul>
                                <li className={`hover-item ${location?.pathname === '/' ? 'active' : ''}`}>
                                    <Link to={'/'}>Home</Link></li>
                                <li className={`hover-item ${location?.pathname === '/about' ? 'active' : ''}`}>
                                    <Link
                                        to={'/about'}>About us
                                    </Link></li>
                            </ul>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={"hover-item"}>Our
                                    Products</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li className={location.search === '?category=lingerie' ? 'active' : ''}>
                                            <Link
                                                to={'/products?category=lingerie'}>Lingerie</Link></li>
                                        <li className={location.search === '?category=mens-boxer' ? 'active' : ''}>
                                            <Link
                                                to={'/products?category=mens-boxer'}>Men's Boxer</Link></li>
                                        <li className={location.search === '?category=swimwear' ? 'active' : ''}><Link
                                            to={'/products?category=swimwear'}>Swimwear</Link></li>
                                        <li className={location.search === '?category=athleisure' ? 'active' : ''}><Link
                                            to={'/products?category=athleisure'}>Athleisure</Link></li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <ul>
                                <li className={`hover-item ${location?.pathname === '/equipment' ? 'active' : ''}`}>
                                    <Link to={'/equipment'}>Our Equipment</Link>
                                </li>
                                <li className={`hover-item ${location?.pathname === '/solutions' ? 'active' : ''}`}>
                                    <Link to={'/solutions'}>Our Solutions</Link></li>
                                {/*<li className={'hover-item'}><Link href={'/contact'}>Key Innovations</Link></li>*/}
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className={"hover-item"}>Key
                                        Innovations</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li className={location.pathname === '/key-innovation/adaptable-clothing' ? 'active' : ''}>
                                                <Link
                                                    to={'/key-innovation/adaptable-clothing'}>Adaptable
                                                    clothing</Link>
                                            </li>
                                            <li className={location.pathname === '/key-innovation/connectable-clothing' ? 'active' : ''}>
                                                <Link
                                                    to={'/key-innovation/connectable-clothing'}>Connectable
                                                    Clothing</Link></li>
                                            <li className={location.pathname === '/key-innovation/atheleisure-clothing' ? 'active' : ''}>
                                                <Link
                                                    to={'/key-innovation/atheleisure-clothing'}>Atheleisure
                                                    Clothing</Link></li>
                                            <li className={location.pathname === '/key-innovation/shapewear' ? 'active' : ''}>
                                                <Link
                                                    to={'/key-innovation/shapewear'}>Shapewear</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <li className={`hover-item ${location?.pathname === '/sustainability' ? 'active' : ''}`}>
                                    <Link to={'/sustainability'}>Sustainability
                                        Efforts</Link></li>
                                <li className={`hover-item ${location?.pathname === '/media' ? 'active' : ''}`}>
                                    <Link
                                        to={'/media'}>Media Center</Link></li>
                                <li className={`hover-item ${location?.pathname === '/contact' ? 'active' : ''}`}>
                                    <Link
                                        to={'/contact'}>Contact</Link></li>
                            </ul>
                        </Accordion>
                    </div>

                    <div className="col-sm-7 menu-items__images d-flex p-0">
                        <div className="col-sm-7 pl-0">
                            <ul className="menu-items__images__list left">
                                {
                                    leftImage && leftImage?.length > 0 &&
                                    leftImage?.map((e, i) => {
                                        return (
                                            <li key={i}><img src={e?.full_path} alt=""/></li>
                                        );
                                    })
                                }
                                {
                                    leftImage && leftImage?.length > 0 &&
                                    leftImage?.map((e, i) => {
                                        return (
                                            <li key={i}><img src={e?.full_path} alt=""/></li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                        <div className="col-sm-5 pr-0">
                            <ul className="menu-items__images__list right">
                                {
                                    rightImage && rightImage?.length > 0 &&
                                    rightImage?.map((e, i) => {
                                        return (
                                            <li key={i}><img src={e?.full_path} alt=""/></li>
                                        );
                                    })
                                }
                                {
                                    rightImage && rightImage?.length > 0 &&
                                    rightImage?.map((e, i) => {
                                        return (
                                            <li key={i}><img src={e?.full_path} alt=""/></li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="menu-items__social">
                    {/*<ul>*/}
                    {/*    <li><a href="https://www.facebook.com/"><img src={social1} alt=""/></a></li>*/}
                    {/*    <li><a href="https://twitter.com/"><img src={social2} alt=""/></a></li>*/}
                    {/*    <li><a href="https://www.instagram.com/"><img src={social3} alt=""/></a></li>*/}
                    {/*    <li><a href="https://youtube.com/"><img src={social4} alt=""/></a></li>*/}
                    {/*</ul>*/}
                </div>
            </StyledMenuItem>
        </>


    );
};

const StyledDesktopMenu = styled.section`
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  //height: 80px;
  width: 100%;
  display: flex;
  transition: all .4s ease;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    right: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.3);
    bottom: 0;
    @media (max-width: 600px) {
      left: 15px;
      right: 15px;
    }
  }


  .container {
    height: 80px;
    display: flex;
    align-items: center;
    position: relative;

    .row {
      width: 100%;
    }
  }

  .menu-desktop__hamburger {

    position: relative;
    display: flex;
    align-items: center;

    a {
      font-size: 16px;
      margin-left: 45px;
      color: ${White};
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 4px;

      &.active {
        color: ${hover};
        pointer-events: none;
      }
    }

    &__lines {
      display: flex;
      margin-right: 30px;
      height: 50px;
      align-items: center;
      cursor: pointer;


      &__inner {
        width: 45px;

        .line {
          height: 1px;
          background-color: #E9E4DC;
          margin-right: auto !important;
          border-radius: 5px;
          transform-origin: right;
          transition: all .6s cubic-bezier(0.4, 0, 0, 1) 0s;;
          width: 30px;

          &:nth-of-type(2) {
            margin: 5px 0;
            width: 20px;
          }

        }

        svg {
          display: none;
        }


      }

      p {
        text-transform: uppercase;
        right: 0;
        font-size: 16px;
        color: #ffffff;
        line-height: 20px;
        //padding-left: 20px;
        letter-spacing: 1px;
        padding-top: 4px;

        &:nth-last-of-type(1) {
          display: none;
        }
      }

      &:hover {
        .line {
          &:nth-of-type(2) {
            width: 30px;
          }

          &:nth-of-type(1), &:nth-of-type(3) {
            width: 15px;
          }

        }
      }

    }

    .controller {
      display: none;
      align-items: center;

      line {
        display: none;
      }

      svg {
        cursor: pointer;
      }

      &.sound {
        line {
          display: block;
        }
      }
    }
  }

  .menu-desktop__logo {
    img {
      height: 60px;
    }
  }

  .menu-desktop__button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      text-transform: uppercase;

      &:nth-of-type(1) {
        margin-right: 50px;
        margin-top: 4px;
      }

      &.active {
        color: ${hover};
        pointer-events: none;
      }
    }

    svg g {
      transition: all .8s ${Transition};
    }

    #Rectangle_6231 {
      opacity: 1;
      stroke-dasharray: 0, 100;
      transition-delay: 0s !important;
    }

    a {
      color: ${White};
      letter-spacing: 1px;

      &:hover {
        svg #Rectangle_6231 {
          stroke-dasharray: 100;
          stroke-width: 1;
        }
      }
    }
  }

  &.menu-open {
    transform: none !important;


    &:after {
      display: none;
    }

    .controller {
      display: none;
    }

    .menu-desktop__hamburger a, .menu-desktop__button a {
      display: none;
    }

    .menu-desktop__hamburger__lines {
      width: 65px;

      .line {
        width: 30px !important;
        background-color: ${text};

        &:nth-last-of-type(1) {
          transform-origin: left;
          transform: rotate(-45deg) translateY(6px) translateX(-2px);
        }

        &:nth-last-of-type(2) {
          opacity: 0;
        }

        &:nth-last-of-type(3) {
          transform: rotate(45deg) translateY(-5px);
          transform-origin: left;

        }
      }


      svg {
        display: block;
      }

      p {
        color: #191818 !important;
        padding-left: 12px;
        display: block;

        &:nth-of-type(1) {
          display: none;
        }
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }

  @media (max-width: 991px) {
    .menu-desktop__hamburger {
      a {
        display: none !important;
      }
    }
  }

  @media (max-width: 767px) {
    .container:after {
      left: 0;
      right: 0;
    }

    .menu-desktop__hamburger__lines, .menu-desktop__hamburger__lines__inner {
      width: unset !important;
    }

    .row {
      flex-direction: row-reverse;
    }

    .menu-desktop__button {
      display: none !important;
    }

    .menu-desktop__hamburger {
      justify-content: end;
      padding-right: 0;

      &__lines {
        margin: 0;

        .line {
          width: 45px !important;

          &:nth-of-type(2) {
            margin: 8px 0;
          }
        }

        p {
          display: none;
        }
      }

      .controller {
        display: none !important;
      }
    }

    .menu-desktop__hamburger {
      min-width: 50%;
      max-width: 50%;
    }

    .menu-desktop__logo {
      min-width: 50%;
      justify-content: start !important;
      max-width: 50%;
    }

    &.menu-open {

      .menu-desktop__logo {
        display: none !important;
      }

      .container:after {
        background-color: #fff;
      }
    }

    .menu-desktop__hamburger__lines {
      p {
        display: none !important;
      }
    }
  }





`;


// menu items
const StyledMenuItem = styled.section`
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9991;
  background-color: #ffffff;
  display: none;
  //display: flex;
  opacity: 0;

  .d-flex {
    width: 100%;
  }

  .menu-items__lists {
    padding: 120px 0 50px 100px;

    ul {
      overflow: hidden;

      li {
        margin-bottom: 20px;
        width: fit-content;

        a {
          font-size: 24px;
          line-height: 28px;
          font-family: ${title};
          color: #b7b4b0;
          text-transform: capitalize;

          &:hover {
            color: ${text} !important;
          }
        }

        &.active {
          a {
            color: ${text};
            pointer-events: none;
          }
        }
      }
    }

    .accordion {
      transform: translateY(100px);
      opacity: 0;
    }

    .accordion-header {
      width: fit-content;
      line-height: inherit;

      button {
        font-size: 24px;
        line-height: 28px;
        border: none;
        box-shadow: none !important;
        background: transparent;
        padding: 0;
        outline: none !important;
        margin-bottom: 20px;
        transition: color .3s ease;
        color: #b7b4b0;
        font-family: ${title};

        &:hover {
          color: ${text};

        }
      }

      &.active {
        button {
          color: ${text};
        }
      }

    }

    .accordion-body {
      ul {
        padding-left: 20px;
        padding-bottom: 10px;

        li {
          &:not(:nth-last-of-type(1)) {
            margin-bottom: 10px;
          }

          a {
            font-size: 24px;
            line-height: 28px;
            font-family: ${textMid};
            font-weight: 500;
          }
        }
      }
    }
  }

  .menu-items__images {

    &__list {
      overflow: hidden;
      height: 100vh;

      li {
        margin-bottom: 30px;

        img {
          object-fit: contain;
          width: 100%;

        }
      }
    }

    .left {
      li {
        transition: none;
      }

    }

    .right {
      transform: rotate(180deg);

      img {
        transform: rotate(-180deg);
        opacity: .2;
      }
    }
  }

  .menu-items__social {
    position: absolute;
    bottom: 50px;
    left: 15px;

    ul {
      display: flex;

      li {
        &:not(:nth-last-of-type(1)) {
          margin-right: 20px;
        }
      }
    }
  }

  @media (min-width: 651px) {
    .menu-items__social {
      display: none;
    }
  }

  @media (max-width: 650px) {
    .menu-items__images {
      display: none !important;
    }

    .menu-items__lists {
      min-width: 100%;
      height: calc(100vh - 80px);
      overflow: auto;
      padding-top: 80px !important;

      ul li a {
        font-size: 32px;
        line-height: 36px;
      }

      .accordion-header button {
        font-size: 32px;
        line-height: 36px;
      }
    }
  }





`;

export default MyComponent;
