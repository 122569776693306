import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import {text} from "../styles/globalStyleVars";

const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    marginSm,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    borderColor
                }) => {


    return (
        <StyledBtn onSubmit={onSubmit} className={`dc-btn`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   marginSm={marginSm}
        >
            {src ? (
                <Link to={src}>
                    <span> {text ? text : 'Learn More'}  </span>
                </Link>
            ) : (
                <a target={target || '_self'}>
                    <span>{text ? text : 'Learn More'}</span>
                </a>
            )}
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: ${props => props.width || 'fit-content'}px;

    a {
      display: flex;
      height: fit-content;
      width: fit-content;
      font-size: ${props => props.fontSize || 16}px;
      margin: 0;
      line-height: ${props => props.lineHeight || 18}px;
      text-transform: uppercase;
      position: relative;
      overflow: hidden;
      z-index: 2;
      cursor: pointer;
      padding-bottom: 5px;

      span {
        transition: color .3s ease;
        color: ${props => props.color || `#010A1A`};
        letter-spacing: 2px;
      }

      &:before {
        position: absolute;
        height: 1px;
        background-color: ${props => props.color ? props.color : text};
        width: 100%;
        left: 0;
        bottom: 0;
        content: '';
        transition: height .4s ease;
      }

      &:hover {
        &:before {
          height: 4px;
        }
      }

      &:focus {
        color: ${props => props.color || `#010A1A`};
      }
    }

    @media (max-width: 600px) {
      margin: ${p => p.marginSm};
    }
  }

`;


export default Button;
