import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import Button from "../Button";
import SingleEquipment from "../SingleEquipment";
import {hover, text} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {


    return (
        <StyledComponent className={"technologies pb-200"}>
            <Container>
                <Row className={"technologies__top"}>
                    <Col sm={{span: 8, offset: 4}} className={"d-flex justify-content-between align-items-center"}>
                        <Title fontSize={'32'} lineHeight={"36px"} text={"Our Technologies"} margin={'0 0 60px 0'}
                               marginSm={"0 0 40px 0"}/>
                        <Button margin={'0 0 60px 0'} marginSm={"0 0 40px 0"} src={"/equipment"}
                                text={'View All Equipment'}/>
                    </Col>
                </Row>
                <Row className={'technologies__content'}>
                    <Col sm={3} className={'technologies__content__sidebar'}>
                        <p>The synergy between our bonding and moulding facilities highlights our commitment to superior
                            craftsmanship and innovation, ensuring the consistent delivery of unparalleled quality
                            products across diverse industries.</p>
                    </Col>

                    <Col sm={{offset: 1, span: 8}}>
                        <Row className={"technologies__items"}>
                            {data?.map(i => (
                                <Col key={i?.data?.id} sm={4}>
                                    <SingleEquipment link={`/equipment/${i?.data?.slug}`} cat={i?.data?.title}
                                                     text={"Ultrasonic Machine"}
                                                     img={i?.images?.list?.[0]?.full_path}/>
                                </Col>
                            ))}

                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background-image: url('/images/static/noise-sm.jpg');
  background-repeat: repeat;
  background-color: #F1EFEA;
  background-blend-mode: screen;
  overflow: hidden;
  @media (max-width: 500px) {
    background: none;
  }

  .technologies__content {
    &__sidebar {
      p {
        padding-top: 20px;
        border-top: 1px solid ${text};
        color: ${hover};
      }
    }
  }

  .technologies__items {
    .col-sm-4 {
      &:nth-child(n+4) {
        display: none;
      }
    }
  }

  @media (max-width: 991px) {
    .technologies__top {
      .col-sm-8 {
        min-width: 100%;
        margin: 0;
        flex-wrap: wrap;
      }
    }
  }
  @media (max-width: 768px) {
    .technologies__content {
      &__sidebar {
        min-width: 100%;
      }

      .col-sm-8 {
        min-width: 100%;
        margin: 40px 0 0 0;

      }
    }
  }

  @media (max-width: 575px) {
    .technologies__items {
      .col-sm-4:not(:nth-last-of-type(1)) {
        margin-bottom: 40px;
      }
    }
  }
`;

export default MyComponent;
