import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {textMid, White} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(90)
    useEffect(() => {
        setOffset(document.querySelector('.footer .container').offsetLeft + 15)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.footer .container').offsetLeft + 15)
        })
    }, [])

    return (
        <StyledComponent className={"capability"}>
            <div className="capability__wrap">
                <Col sm={7} className={"capability__wrap__desc"} style={{paddingLeft: offset + 'px'}}>
                    <Title color={White} margin={"0 0 20px 0"} text={data?.section_data?.subtitle} fontSize={"32"}/>
                    <p>{ReactHtmlParser(data?.section_data?.short_desc)}</p>
                    <ul>
                        {data?.posts?.list?.map(i => (
                            <li>
                                <img src={i?.images?.[0]?.full_path} alt="icon"/>
                                <div className="wrap">
                                    <h5>{ReactHtmlParser(i?.data?.title)}</h5>
                                    <p>{ReactHtmlParser(i?.data?.short_desc)}</p>
                                </div>
                            </li>
                        ))}

                    </ul>
                </Col>

                <Col sm={5} className={'capability__img p-0 parallax-image'}>
                    <img src={data?.images?.list?.[0]?.full_path} alt={'side'}/>
                </Col>
            </div>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #F1EFEA;

    @media (min-width: 1900px) {
        margin-bottom: -100px;
    }

    .capability__wrap {
        display: flex;
        flex-wrap: wrap;

        position: relative;

        &:after {
            background-image: url('/images/static/noise-sm.jpg');
            background-repeat: repeat;
            background-color: #F1EFEA;
            background-blend-mode: screen;
            height: 40%;
            top: 0;
            left: 0;
            width: 100%;
            content: '';
            position: absolute;
            @media (max-width: 500px) {
                background: none;
            }
        }

        &__desc {
            background-color: #000;
            padding-top: 125px;
            padding-bottom: 125px;
            padding-right: 100px;
            position: relative;
            background-image: url('/images/dynamic/lather.jpg');
            background-repeat: repeat;
            z-index: 5;
            height: fit-content;

            p {
                font-size: 16px;
                line-height: 20px;
                color: #b7b4b0;
            }

            ul {
                margin-top: 80px;
                padding-left: 80px;


                li {
                    display: flex;
                    gap: 0 60px;
                    align-items: flex-start;

                    &:not(:nth-last-of-type(1)) {
                        margin-bottom: 80px;
                    }

                    .wrap {
                        max-width: calc(100% - 60px);

                        h5 {
                            font-size: 22px;
                            line-height: 24px;
                            font-weight: 500;
                            font-family: ${textMid};
                            color: ${White};
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }

        .capability__img {
            position: relative;
            z-index: 5;

            img {
                width: 100%;
                object-fit: contain;
                margin-top: 250px;
            }
        }
    }

    @media (max-width: 991px) {
        .capability__wrap {
            flex-direction: column-reverse;

            &__desc {
                min-width: 100%;
                padding-right: 15px;

                ul {
                    padding-left: 0;

                    li {
                        flex-wrap: wrap;

                        .wrap {
                            min-width: 100%;
                            margin-top: 25px;
                        }

                        &:not(:nth-last-of-type(1)) {
                            margin-bottom: 40px;
                        }
                    }
                }
            }

            .capability__img {
                img {
                    margin-top: 0;
                }
            }

        }
    }
`;

export default MyComponent;
