import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import List from "../../components/media/List";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchMediaCenter} from "../../api/redux/media";
import {useHistory} from "react-router-dom";
import {SplitUp} from "../../components/animations/TextAnimation";
import {ParallaxImage, ParallaxImg, ParallaxItem} from "../../components/animations/Parallax";

const MyComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [activeCat, setActiveCat] = useState(0)
    const dispatch = useDispatch();
    const history = useHistory();

    SplitUp()
    ParallaxImg()
    ParallaxImage()
    ParallaxItem()

    // api call
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                let api_url = apiEndPoints.GET_BLOG;

                let api_params = {
                    [ApiParam.CATEGORY_ID]: activeCat ? activeCat : 0,
                    [ApiParam.IMAGE]: "yes",
                    [ApiParam.POST]: "yes",
                };

                await dispatch(fetchMediaCenter([api_url, api_params]));
                setIsLoading(false);
            } catch (err) {
                // Handle errors if needed
                console.error("Error fetching products:", err);
                setIsLoading(false);
            }
        };
        fetchData();

    }, [dispatch, activeCat]);

    const getData = useSelector((state) => state.media);
    const banner = getData?.posts?.page_data;
    const mediaData = getData?.posts?.data;
    const categories = getData?.posts?.categories;

    const handleCat = useCallback(
        (e, a) => {
            setActiveCat(e);
            // Update the URL with the new activeCat value
            history.push(`?category=${a ? a : 'all'}`);
        },
        [history]
    );


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Media | Triple Apparel Limited</title>
                <meta
                    content="Triple Apparels Ltd is the latest addition to the Partex Star family, a household name in Bangladesh. We are well poised to position ourselves as one of the largest global apparel manufacturers with world-class capabilities and standards."
                    name="description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={banner?.page_data?.subtitle} text={banner?.page_data?.short_desc}
                             img={banner?.images?.list?.[0]?.full_path}/>
                <List activeCat={activeCat} handleCat={handleCat} categories={categories} mediaData={mediaData}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
