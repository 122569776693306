import {createGlobalStyle, css} from 'styled-components';
import {hover, text, title, White} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

    ${createCSS()}
    #root {
        min-height: 100vh;
        overflow-x: hidden;
    }

    @font-face {
        font-family: 'Ogg';
        src: url('/fonts/Ogg-Italic.woff2') format('woff2'),
        url('/fonts/Ogg-Italic.woff') format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Gill Sans MT Pro Book';
        src: url('/fonts/GillSansMTPro-Book.woff2') format('woff2'),
        url('/fonts/GillSansMTPro-Book.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Ogg';
        src: url('/fonts/Ogg-Roman.woff2') format('woff2'),
        url('/fonts/Ogg-Roman.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Gill Sans MT Pro';
        src: url('/fonts/GillSansMTPro-Medium.woff2') format('woff2'),
        url('/fonts/GillSansMTPro-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }


    body {
        font-family: 'Gill Sans MT Pro Book', Arial, Helvetica, freesans, sans-serif !important;
        font-style: normal;
        margin: 0;
        color: ${text};
        padding: 0;
        overflow-x: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100vh;
        font-size: 16px;
        line-height: 20px;
    }

    a {
        transition: color .3s ease;
        text-decoration: none;

        &:hover {
            color: ${hover} !important;
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }

        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
            color: ${text};
        }
    }

    ::selection {
        background: ${hover};
        color: #FFF;
    }

    p, a, h4, h3, h5, h6, h2, h1 {
        color: ${text};
        margin: 0;
        font-weight: normal;
    }

    ul {
        margin: 0;
        padding: 0
    }

    li {
        list-style: none;
    }

    img {
        max-width: 100%;
        object-fit: contain;
    }


    .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
        outline: none;
        box-shadow: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        background-color: white;
        -webkit-text-fill-color: inherit;
        -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
        transition: unset;
    }


    table {
        width: 100%;
    }

    form div {
        position: relative;
    }

    .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;

        &:focus {
            box-shadow: none;
        }
    }

    .p-0 {
        padding: 0 !important;
    }

    .pl-0 {
        padding-left: 0;
    }

    .pr-0 {
        padding-right: 0;
    }

    .pt-200 {
        padding-top: 200px;
        @media (max-width: 767px) {
            padding-top: 120px;
        }
    }

    .pb-200 {
        padding-bottom: 200px;
        @media (max-width: 767px) {
            padding-bottom: 120px;
        }
    }

    .pt-160 {
        padding-top: 160px;
        @media (max-width: 767px) {
            padding-top: 100px;
        }
    }

    .pb-160 {
        padding-bottom: 160px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pb-130 {
        padding-bottom: 130px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pt-100 {
        padding-top: 100px;
        @media (max-width: 767px) {
            padding-top: 60px;
        }
    }

    .pb-100 {
        padding-bottom: 100px;
        @media (max-width: 767px) {
            padding-bottom: 60px;
        }
    }

    .pt-80 {
        padding-top: 80px;
        @media (max-width: 767px) {
            padding-top: 40px;
        }
    }

    .pb-80 {
        padding-bottom: 80px;
        @media (max-width: 767px) {
            padding-bottom: 40px;
        }
    }


    .MuiDrawer-paper {
        width: 500px !important;
        padding: 20px;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    .swiper-button-disabled {
        opacity: 0 !important;
    }

    @media (min-width: 1500px) {
        .container {
            //min-width: 1366px;
            min-width: 85%;
            margin: auto;
        }
    }

    @media (max-width: 1199px) and (min-width: 768px) {
        .container, .container-lg, .container-md, .container-sm {
            max-width: 90%;
            margin: auto;
        }
    }


    @media (max-width: 767px) {
        .container, .container-sm {
            max-width: 100%;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }


    //react select
    .css-yk16xz-control, .css-1pahdxg-control {
        height: 50px;
        border-radius: 0 !important;
        padding-left: 5px;
        font-size: 16px;
        outline: none !important;
        border-color: #D9D9D9 !important;
        box-shadow: none !important;

        .css-1wa3eu0-placeholder {
            font-weight: 300;
            line-height: 21px;
            color: rgba(0, 0, 0, 0.5);
            outline: none;
        }

        .css-1okebmr-indicatorSeparator {
            display: none;
        }

        .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
            margin-right: 10px;
        }
    }

    .css-2613qy-menu {
        border-radius: 0 !important;
        margin-top: 0 !important;
    }

    //animation class


    .info-window {
        max-width: 200px;
    }

    .gm-style-iw {
        border-radius: 0 !important;
    }

    .swiper-pagination-bullet {
        outline: none;
    }

    .css-nmuc1a-menu {
        z-index: 5 !important;
    }


    .map-info__img {
        img {
            height: 100px;
            margin-bottom: 12px;
            object-fit: cover;
        }
    }

    .map-info__content {
        h4 {
            font-size: 20px;
        }

        p {
            margin-bottom: 5px;
        }
    }

    .overlay {
        position: fixed;
        height: 100vh;
        width: 100%;
        //background-color: rgba(0,0,0,0.5);
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 9;
        display: none;

        &.show {
            display: block;
        }
    }

    //.form-control.has-error {
    //  border-color: #dc004e !important;
    //}
    //
    //.has-error .find-retainer-filter__control {
    //  border-color: #dc004e !important;
    //}

    .loader {
        position: fixed; /* Use fixed instead of absolute for viewport centering */
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #3E3937; /* Adjust the alpha channel for transparency */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;

        .spinner {
            border: 4px solid #231F20;
            border-radius: 50%;
            border-top: 4px solid #F1EFEA;
            width: 60px;
            height: 60px;
            animation: spin 1s linear infinite;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    //preloader
    .content-loader {
        position: absolute;
        height: 70%;
        width: 70%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .loading-before-submit {
        position: fixed;
        height: 100vh;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.65);
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 40px;
        }
    }


    .swiper-slide {
        height: auto;
    }

    .slick-slide {
        div {
            outline: none !important
        }
    }

    button, button:active, button:focus, button:focus-visible {
        outline: none !important;
        box-shadow: none !important;
    }


    .hover {
        position: relative;
        overflow: hidden;

        span {
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: ${hover};
            transition: all .5s ease;
            border-radius: 19px;
        }

        &:hover {
            &:after {
                height: 100%;
                width: 100%;
            }
        }
    }


    .modal-backdrop {
        background-color: rgb(34 31 31 / 90%);
        min-width: 100%;
        //z-index: 9999;

        &.show {
            opacity: 1;
        }
    }


    .valid {
        color: ${hover};
        position: absolute;
        font-size: 12px;
        top: 44px;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }


    //menu fixed
    .scroll-down .menu-desktop {
        transform: translate3d(0, -100%, 0);


    }

    .scroll-up .menu-desktop, .menu-modify .menu-desktop {
        background-color: #3E3937;

        &:after {
            opacity: 0;
        }

        .menu-desktop__hamburger__lines {

            &__inner .line {
                background-color: ${White};
            }

            p {
                color: ${White};
            }
        }

        .menu-desktop__hamburger .controller svg {
            path {
                fill: #191818;
            }

            line {
                stroke: #191818;
            }
        }

        .dc-btn a {
            color: #191818;

            &:hover {
                color: #191818 !important;
            }

            &:after, &:before {
                box-shadow: 0 0 0 1px #191818;
            }
        }
    }

    .form-control:disabled {
        background-color: transparent;
    }

    @media (max-width: 600px) {
        .prevent-overflow {
            overflow: hidden;
            height: 100vh;
        }
    }

    .Toastify__toast-container {
        z-index: 99999999;
    }

    .mobile-menu {
        #fb-root, .fb_reset {
            display: none !important;
            opacity: 0 !important;
            visibility: hidden !important;
        }
    }

    .slick-slide {
        -webkit-transform: translate3d(0, 0, 0);
    }

    .reveal, .revealFast {
        overflow: hidden;
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;

        img {
            transform-origin: left;
        }

        .global-image {
            background: transparent;
        }
    }

    //calender
    ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
        border-radius: 0 !important;
    }

    //video modal 
    .modal-video-close-btn:before, .modal-video-close-btn:after {
        background-color: ${hover};
    }

    .page-loader {
        position: fixed;
        background-color: rgb(36, 24, 67);
        width: 100vw;
        z-index: 999999999;
        overflow: hidden;
        //opacity: 0;
        //height: 100vh;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        //left: 0; //width: 0;
        //transition: all 2.6s ease;
        //.anim-logo {
        //  width: 130px;
        //  overflow: hidden;
        //  height: fit-content;
        //  position: absolute;
        //  left: 0;
        //  right: 0;
        //  top: 0;
        //  bottom: 0;
        //  opacity: 0;
        //  margin: auto;
        //  z-index: 2;
        //
        //  img {
        //    height: 55px;
        //  }
        //}

        //.hide-logo {
        //  background-color: #010A1A;
        //  width: 50%;
        //  height: 100vh;
        //  top: 0;
        //  left: 0;
        //  right: 0;
        //  margin: auto;
        //  position: absolute;
        //  z-index: 999;
        //}

        .pre-loader__img {
            //position: fixed;
            height: 100px;
            width: 100px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgb(36, 24, 67);
            z-index: 99999999;

            //flex: 1;
            margin: auto;
            opacity: 0;

            img {
                display: block;
            }

            .top {
                height: 8px;
            }

            .bottom {
                height: 30px;
                margin-top: 5px;
                animation: flip 1s linear infinite;
            }

            @keyframes flip {
                0% {
                    transform: rotateY(0deg)
                }
                100% {
                    transform: rotateY(180deg)
                }
            }
        }

    }

    //------------------------animation
    .split-parent {
        overflow: hidden;
    }

    .split-child {
        overflow: hidden;
    }

    .reveal {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            transform-origin: left;
        }

        .global-image {
            background: transparent;
        }
    }


    //page transition
    .page-transition {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: ${hover};
        z-index: 999999999;
        display: none;
        opacity: 0;
        //display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            height: 80px;
            opacity: 0;
        }
    }


    #smooth-content {
        will-change: transform;
    }

    //team popup
    .team-modal {
        background-color: #3E3937;
        height: 100vh;
        z-index: 99999;
        overflow: hidden !important;
        width: 45%;
        right: 0;
        left: auto;
        padding: 0px 70px 30px 70px !important;

        &__close {
            height: 37px;
            width: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: transparent;
            border: 1px solid ${White};
            border-radius: 50%;
            position: absolute;
            right: -10px;
            top: -60px;
            transition: .3s ease;

            &:hover {
                background-color: ${hover};
            }
        }

        .modal-dialog {
            height: 100vh;
            background-color: transparent;
            min-width: 100%;
            margin: 0;
        }

        .modal-body {
            height: 100vh;
        }

        .modal-content {
            background-color: transparent;
            margin: 100px 0;
            //overflow: scroll;
            //height: calc(100vh - 120px);
            border: none;

            .team-modal__content {
                h4 {
                    color: #f5f5f5;
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 24px;
                    //border-top: 1px solid rgba(245, 245, 245, 0.2);
                    margin-bottom: 5px;
                }

                h5 {
                    color: rgba(245, 245, 245, 0.5);
                    font-size: 16px;
                    margin-bottom: 25px;
                }

                p {
                    color: #f5f5f5;
                    margin-bottom: 20px;
                }

                ul {
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    li {
                        &:not(:nth-last-child(1)) {
                            margin-right: 20px;
                        }

                        a {
                            transition: opacity .5s ease;

                            &:hover {
                                // rect {
                                    //   fill: ${text}
                                // }
                                //
                                // path {
                                    //   fill: ${White}
                                // }
                                opacity: .7;
                            }
                        }

                    }
                }
            }
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 0 20px 10px 20px !important;
            .close-button {
                right: 20px;
                position: fixed;
            }

            .modal-content {
                //padding-top: 80px;

                .container {
                    //height: calc(100vh - 100px);
                    //overflow: scroll;

                    h3 {
                        margin-top: 30px;
                        font-size: 28px;
                        line-height: 35px;
                    }
                }
            }

            //overflow: scroll !important;

        }
    }


    .simplebar-scrollbar:before {
        background-color: #FFF !important;
    }

    .simplebar-track.simplebar-vertical {
        width: 7px;
    }

    .simplebar-track {
        right: -7px;
    }

    .menu-action .menu-desktop {
        background-color: transparent !important;
    }

    @media (max-width: 600px) {
        .menu-action {
            height: ${`100svh`};
            overflow: hidden;
        }
    }

    .menu-action {
        .menu-desktop__logo {
            opacity: 0;
        }
    }


    ///* total width */
    //body::-webkit-scrollbar {
    //  background-color: #fff;
    //  width: 10px;
    //  position: fixed;
    //  opacity: 0;
    //}
    //
    ///* background of the scrollbar except button or resizer */
    //body::-webkit-scrollbar-track {
    //  background-color: #fff;
    //  position: fixed;
    //  opacity: 0;
    //}
    //
    ///* scrollbar itself */
    //body::-webkit-scrollbar-thumb {
    //  background-color: rgba(173, 173, 178, 0.93);
    //  border-radius: 8px;
    //  border: 2px solid #fff;
    //  opacity: 0;
    //}
    //
    ///* set button(top and bottom of the scrollbar) */
    //body::-webkit-scrollbar-button {
    //  display: none;
    //}
`;



