import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useParams} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Detail from "../../components/product/Detail";
import RelatedProduct from "../../components/product/RelatedProduct";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchProductDetail} from "../../api/redux/products";
import {SplitUp} from "../../components/animations/TextAnimation";
import {ParallaxImage, ParallaxImg, ParallaxItem} from "../../components/animations/Parallax";

const MyComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch()
    const getData = useSelector((state) => state.products);
    let {slug} = useParams();

    SplitUp()
    ParallaxImg()
    ParallaxImage()
    ParallaxItem()

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                let api_url = apiEndPoints.PRODUCT_DATA;

                let api_params = {
                    [ApiParam.TYPE]: "slug",
                    [ApiParam.VALUE]: slug,
                    [ApiParam.IMAGE]: "yes",
                    [ApiParam.POST]: "yes",
                };

                await dispatch(fetchProductDetail([api_url, api_params]));

                setIsLoading(false);
            } catch (err) {
                // Handle errors if needed
                console.error("Error fetching products:", err);
                setIsLoading(false);
            }
        }
        fetchData()
    }, [dispatch]);

    const details = getData?.detail?.data;
    const relatedProducts = getData?.detail?.relatedProducts?.list?.filter(item => item?.product_data?.slug !== slug);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Product | Triple Apparel Limited</title>
                <meta
                    content="Triple Apparels Ltd is the latest addition to the Partex Star family, a household name in Bangladesh. We are well poised to position ourselves as one of the largest global apparel manufacturers with world-class capabilities and standards."
                    name="description"/>
            </Helmet>

            <StyledComponent>
                <Detail data={details}/>
                <RelatedProduct data={relatedProducts}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
