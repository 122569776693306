import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {textMid} from "../../styles/globalStyleVars";
import Title from "../Title";

const MyComponent = ({data}) => {

    console.log('value', data)

    const [offset, setOffset] = useState(90)
    useEffect(() => {
        setOffset(document.querySelector('.footer .container').offsetLeft + 15)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.footer .container').offsetLeft + 15)
        })
    }, [])

    return (
        <StyledComponent className={'value'}>
            <div className="value__container" style={{paddingLeft: offset + 'px'}}>
                <div className="value__content" style={{paddingRight: offset + 'px'}}>
                    <Title color={"#3E3937"} fontSize={"32"} lineHeight={"36"}
                           text={data?.section_data?.title}/>
                    <ul>
                        {data?.posts?.list?.map(i => (
                            <li>
                                <span>{i?.data?.title}</span>
                                {i?.data?.short_desc}
                            </li>
                        ))}


                    </ul>
                </div>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    height: 50%;
    top: 0;
    left: 0;
    right: 0;
    background-image: url(/images/dynamic/lather.jpg);
    background-repeat: repeat;
  }

  .value__container {
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .value__content {
    padding: 120px 100px;
    background-color: #F1EFEA;
    display: flex;
    gap: 0 100px;

    .title {
      min-width: 200px;
    }


    ul {
      width: auto;

      li {
        position: relative;
        counter-increment: list-counter;
        padding-left: 40px;
        padding-bottom: 40px;
        margin-bottom: 40px;

        span {
          font-size: 20px;
          font-weight: 500;
          font-family: ${textMid};
          line-height: 24px;
          display: block;
          margin-bottom: 10px;
        }

        &:not(:nth-last-of-type(1)) {
          border-bottom: 1px solid rgba(183, 180, 176, 0.30);
        }

        &:before {
          content: counter(list-counter, upper-alpha);
          margin-right: 5px;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background-color: #3E3937;
          position: absolute;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;
          top: 2px;
          color: #FFF;
          padding-top: 4px;
        }

        h4 {
          font-size: 18px;
          line-height: 22px;
          font-weight: 500;
          font-family: ${textMid};
          margin-bottom: 20px;
        }

        p {
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
        }

        &:nth-last-of-type(1) {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }

      p {
        margin-top: 40px;
      }
    }
  }

  @media (max-width: 991px) {
    .value__content {
      flex-wrap: wrap;

      ul {
        margin-top: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    .value__content {
      padding: 70px 20px !important;
    }
  }
`;

export default MyComponent;
