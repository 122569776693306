import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {hover, textMid, title, White} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'desc pt-200 pb-160'}>
            <img className="desc__shadow parallax-left" src="/images/dynamic/desc-shadow.svg" alt=""/>
            <Container>
                <Row className={"desc__top"}>
                    <Col sm={9}>

                        <h2>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                        <p>{reactHtmlParser(data?.section_data?.short_desc)}</p>
                    </Col>
                </Row>

                <Row className={"desc__content"}>
                    {
                        data?.posts?.list && data?.posts?.list?.length > 0 &&
                        data?.posts?.list?.map((e, i)=>{
                            return(
                                <Col sm={4} key={i}>
                                    <img src={e?.images?.[0]?.full_path} alt=""/>
                                    <h4>{reactHtmlParser(e?.data?.title)}</h4>
                                    <p>{reactHtmlParser(e?.data?.short_desc)}</p>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #231F20;
  position: relative;
  overflow: hidden;
  background-image: url(/images/dynamic/lather.jpg);
  background-repeat: repeat;
  z-index: 5;

  .desc__shadow {
    position: absolute;
    top: -30px;
    max-width: 90%;
  }

  .desc__top {
    h2 {
      font-size: 40px;
      line-height: 58px;
      margin: 0 0 80px 0;
      color: ${White};
      text-transform: uppercase;
      font-family: ${title};
      @media (max-width: 600px) {
        font-size: 36px !important;
        line-height: 44px !important;
        margin-bottom: 40px;
      }
    }

    p {
      color: ${hover};

      &:not(:nth-last-of-type(1)) {
        margin-bottom: 20px;
      }
    }
  }

  .desc__content {
    margin-top: 60px;
    gap: 0 120px;

    .col-sm-4 {
      max-width: calc(33.333333% - 120px);
      margin-bottom: 60px;
    }

    img {
      margin-bottom: 20px;
    }

    h4 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      font-family: ${textMid};
      margin-bottom: 20px;
      color: ${White};
    }

    p {
      color: #B7B4B0;
    }
  }

  @media (max-width: 991px) {
    .desc__content {
      gap: 0 20px;

      .col-sm-4 {
        max-width: calc(33.333333% - 20px);
      }
    }
  }

  @media (max-width: 575px) {
    .desc__content {
      .col-sm-4 {
        min-width: 100%;

        &:nth-last-of-type(1) {
          margin: 0;
        }
      }
    }
  }
`;

export default MyComponent;
