import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Desc from "../../components/Innovation/Desc";
import InnerBanner from "../../components/InnerBanner";
import Value from "../../components/Innovation/Value";
import RelatedProduct from "../../components/Innovation/RelatedProduct";
import BIgBanner from "../../components/home/BIgBanner";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchInnovation} from "../../api/redux/innovation";
import {useDispatch, useSelector} from "react-redux";
import reactHtmlParser from "react-html-parser";
import {SplitUp} from "../../components/animations/TextAnimation";
import {ParallaxImage, ParallaxImg, ParallaxItem} from "../../components/animations/Parallax";

const MyComponent = () => {
    const dispatch = useDispatch();
    const location = useLocation()

    SplitUp()
    ParallaxImg()
    ParallaxImage()
    ParallaxItem()

    useEffect(() => {
        let api_url = apiEndPoints.CATEGORY_DATA;
        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: location.pathname.replace('/key-innovation/', ''),
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.SPECIFICATION]: "yes",
            [ApiParam.GALLERY]: "yes",
        };

        dispatch(fetchInnovation([api_url, api_params]));
    }, [location.pathname]);

    const getData = useSelector(store => store.innovationReducer)
    const banner = getData?.posts?.data?.category_details?.sections?.find(f => f?.section_data?.search_slug === "Banner");
    const desc = getData?.posts?.data?.category_details?.sections?.find(f => f?.section_data?.search_slug === "Overview");
    const value = getData?.posts?.data?.category_details?.sections?.find(f => f?.section_data?.search_slug === "Why");
    const cta = getData?.posts?.data?.category_details?.sections?.find(f => f?.section_data?.search_slug === "CTA");
    const relatedProduct = getData?.posts?.data?.category_products?.list;

    console.log('getData', getData)

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${banner?.section_data?.subtitle} Triple Apparel Limited`} </title>
                <meta
                    content="Triple Apparels Ltd is the latest addition to the Partex Star family, a household name in Bangladesh. We are well poised to position ourselves as one of the largest global apparel manufacturers with world-class capabilities and standards."
                    name="description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={banner?.section_data?.subtitle}
                             text={banner?.section_data?.short_desc}
                             img={banner?.images?.list?.find(f => f?.is_desktop === 'on')?.full_path}
                             imgSm={banner?.images?.list?.find(f => f?.is_mobile === 'on')?.full_path ? banner?.images?.list?.find(f => f?.is_mobile === 'on')?.full_path : banner?.images?.list?.find(f => f?.is_desktop === 'on')?.full_path}/>
                <Desc data={desc}/>
                <Value data={value}/>
                <RelatedProduct data={relatedProduct}/>
                <BIgBanner title={cta?.section_data?.subtitle}
                           text={reactHtmlParser(cta?.section_data?.short_desc)}
                           img={cta?.images?.list?.find(f => f?.is_desktop === 'on')?.full_path}
                           imgSm={cta?.images?.list?.find(f => f?.is_mobile === 'on')?.full_path}
                />
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
