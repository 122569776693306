import React from 'react';
import styled from 'styled-components';
import {Img} from "./Img";
import {Link} from "react-router-dom";
import {hover, text, textMid, title, White} from './../styles/globalStyleVars'

const ProjectBox = ({img, title, link, type, date, month, year}) => {
    return (
        <StyledProjectBox>
            <div className="wrapper">
                <div className='blog-single'>
                    <div className="blog-single__inner">
                        <Link to={link}/>
                        <Img src={img}/>
                        <div className="blog-single__inner__content">
                            <div className="blog-single__inner__content__top">
                                <h4>
                                    {title}
                                </h4>
                            </div>
                            <div className="blog-single__inner__content__bottom">
                                <div className="date">
                                    <h2>{date}</h2>
                                    <p className={'meta-font'}>{month} {year}</p>
                                </div>
                                <p className={'meta-font cat'}>{type}</p>
                            </div>
                        </div>
                        <div className="noise"/>
                    </div>
                </div>
            </div>
        </StyledProjectBox>

    )
};


const StyledProjectBox = styled.div`

  .global-image {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #000;
      opacity: 0.3;
      height: 100%;
      width: 100%;
    }
  }

  .blog-single {
    border: 1px solid ${hover};

    &__inner {
      padding-top: calc(490 / 370 * 100%);
      position: relative;
      overflow: hidden;

      a {
        position: absolute;
        inset: 0;
        z-index: 3;
      }

      &__content {

        &__top {

          h4 {
            position: absolute;
            top: 40px;
            left: 40px;
            right: 40px;
            z-index: 2;
            color: ${text};
            margin: 0;
            transition: all .3s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            font-size: 28px;
            font-weight: 500;
            font-family: ${textMid};
            line-height: 32px;
          }

        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 40px;
          right: 40px;
          bottom: 30px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          z-index: 2;
          align-items: flex-end;

          h2 {
            color: ${text};
            font-size: 60px;
            font-family: ${title};
          }

          p {
            color: ${text};
          }


        }

        .cat {
          color: ${hover};
        }
      }


      .noise {
        position: absolute;
        inset: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        transition: all .4s ease;

        &:after {
          content: '';
          background-image: url('/images/static/background-noise.png');
          background-blend-mode: multiply;
          width: 100%;
          height: 100%;
          position: absolute;
          inset: 0;
          opacity: 0.2;
        }

      }

      &:hover {
        .blog-single__inner__content:after {
          height: 0;
        }

        .noise {
          height: 0;
        }

        .blog-single__inner__content__top {
          h4 {
            //opacity: 0;
            //transform: translateY(-20px);
            color: ${White};;
          }

          p {
            transform: none;
            //opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: ${White};

          h2 {
            color: ${White};
          }

          p {
            color: ${White};

            span {
              color: ${White};
            }
          }
        }
      }

    }
  }

`;

export default React.memo(ProjectBox);














