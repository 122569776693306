import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Desc from "../../components/Solutions/Desc";
import Value from "../../components/Solutions/Value";
import Innovation from "../../components/Solutions/Innovation";
import BIgBanner from "../../components/home/BIgBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchSolutionData} from "../../api/redux/solution";
import reactHtmlParser from "react-html-parser";
import {SplitUp} from "../../components/animations/TextAnimation";
import {ParallaxImage, ParallaxImg, ParallaxItem} from "../../components/animations/Parallax";

const MyComponent = () => {
    const dispatch = useDispatch();

    SplitUp()
    ParallaxImg()
    ParallaxImage()
    ParallaxItem()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "our-solution",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchSolutionData([api_url, api_params]));
    }, []);

    const getData = useSelector((state) => state.solution);
    const banner = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "solution-banner");
    const overview = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "solution-overview");
    const services = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "services-list");
    const serviceBlog = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "service-blog");
    const cta = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "cta-solution");


    console.log('banner', banner)

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Solutions | Triple Apparel Limited</title>
                <meta
                    content="Triple Apparels Ltd is the latest addition to the Partex Star family, a household name in Bangladesh. We are well poised to position ourselves as one of the largest global apparel manufacturers with world-class capabilities and standards."
                    name="description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={banner?.section_data?.subtitle}
                             text={banner?.section_data?.short_desc}
                             img={banner?.images?.list?.find(f => f?.is_desktop === 'on')?.full_path}
                             imgSm={banner?.images?.list?.find(f => f?.is_mobile === 'on')?.full_path ? banner?.images?.list?.find(f => f?.is_mobile === 'on')?.full_path : banner?.images?.list?.find(f => f?.is_desktop === 'on')?.full_path}
                />
                <Desc data={overview}/>
                <Value data={services}/>
                <Innovation data={serviceBlog}/>
                <BIgBanner title={cta?.section_data?.subtitle}
                           text={reactHtmlParser(cta?.section_data?.short_desc)}
                           img={cta?.images?.list?.find(f => f?.is_desktop === 'on')?.full_path}
                           imgSm={cta?.images?.list?.find(f => f?.is_mobile === 'on')?.full_path ? cta?.images?.list?.find(f => f?.is_mobile === 'on')?.full_path : cta?.images?.list?.find(f => f?.is_desktop === 'on')?.full_path}
                />
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
