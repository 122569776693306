import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import About from "../../components/About";
import Innovative from "../../components/about/Innovative";
import Ceo from "../../components/about/Ceo";
import Team from "../../components/about/Team";
import Partners from "../../components/Partners";
import BIgBanner from "../../components/home/BIgBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchAboutData} from "../../api/redux/about";
import {SplitUp} from "../../components/animations/TextAnimation";
import {ParallaxImage, ParallaxImg, ParallaxItem} from "../../components/animations/Parallax";


const MyComponent = () => {
    const dispatch = useDispatch();
    SplitUp()
    ParallaxImg()
    ParallaxImage()
    ParallaxItem()
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "about-us",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchAboutData([api_url, api_params]));
    }, [dispatch]);

    const getData = useSelector((state) => state.about);
    let page_data = getData?.posts?.data?.page_data;
    const banner = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "about-info");
    const overview = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "overview");
    const innovating = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "innovative-wellbeing");
    const ceo = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "ceo-message");
    const management = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "management");
    const partners = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "partners");
    const aboutCta = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "about-cta");

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            if (getData.loading) {
                setLoading(false)
            } else {
                setLoading(true)
            }
        }, 1000)
    }, [getData])

    // SplitUp(getData)
    return (
        <HelmetProvider>
            <Helmet>
                {/*<meta charSet="utf-8"/>*/}
                {/*<title>About | Triple Apparel Limited</title>*/}
                {/*<meta*/}
                {/*    content="Triple Apparels Ltd is the latest addition to the Partex Star family, a household name in Bangladesh. We are well poised to position ourselves as one of the largest global apparel manufacturers with world-class capabilities and standards."*/}
                {/*    name="description"/>*/}
                <title>{`${page_data?.meta_title ? page_data?.meta_title : ''}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc ? page_data?.meta_desc : ''}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title ? page_data?.og_title : ''}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc ? page_data?.og_desc : ''}/>
                }

            </Helmet>

            <StyledComponent loading={loading}>
                <InnerBanner img={banner?.images?.list?.filter((f) => f?.is_desktop === "on")?.[0]?.full_path}
                             imgSm={banner?.images?.list?.filter((f) => f?.is_mobile === "on")?.[0]?.full_path}
                             text={banner?.section_data?.short_desc} title={banner?.section_data?.subtitle}/>
                <About data={overview} icon={"/images/static/cloth-red.png"}/>
                <Innovative data={innovating}/>
                <Ceo data={ceo}/>
                <Team data={management}/>
                <Partners title={partners?.section_data?.subtitle} data={partners}/>
                <BIgBanner img={aboutCta?.images?.list?.filter((f) => f?.is_desktop === "on")?.[0]?.full_path}
                           linkText={aboutCta?.section_data?.short_desc}
                           title={aboutCta?.section_data?.subtitle}
                           text={aboutCta?.section_data?.description}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
    .about {

        &:before {
            transition: opacity .3s ease;
            ${p => p.loading ? 'opacity:1' : 'opacity:0'}
        }

        .dc-btn {
            display: none;
        }
    }
`;

export default MyComponent;
