import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "./Title-v2";

const MyComponent = ({data, title}) => {
    return (
        <StyledComponent className={'pt-200 pb-160 partners'}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <Title fontSize={'32'} lineHeight={'32'} text={title} margin={'0 0 40px 0'}/>
                    </Col>
                </Row>

                <Row className={'partners__list'}>
                    {
                        data?.images?.list && data?.images?.list?.length > 0 &&
                        data?.images?.list?.map((e, i) => {
                            return (
                                <Col xs={6} sm={3} md={2} key={i}>
                                    <div className="partners__list__single">
                                        <a href={e?.short_title}>
                                            <img src={e?.full_path} alt=""/>
                                        </a>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .partners__list__single {
    padding-top: calc(100%);
    position: relative;
    border: 1px solid rgba(183, 180, 176, 0.2);
    margin-bottom: 30px;
    box-shadow: none;
    transition: .5s ease;

    a {
      position: absolute;
      inset: 0;
      z-index: 2;
    }

    img {
      position: absolute;
      inset: 10px;
      margin: auto;
      max-width: calc(90% - 20px);
    }

    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    }
  }
`;

export default MyComponent;
