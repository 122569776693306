import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {Img} from "../Img";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import {text} from "../../styles/globalStyleVars";
// import SliderNextWhite from "../SliderNextWhite";
// import SliderPrevWhite from "../SliderPrevWhite";


const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(0)
    const [itemHeight, setItemHeight] = useState(0)
    useEffect(() => {
        // offset
        setOffset(document.querySelector('.container')?.offsetLeft + 15)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.container')?.offsetLeft + 15)
        })

        // set section height
        if (window.innerWidth > 991) {
            setTimeout(() => {

                const getHeight = document.querySelector('.feature-project').clientHeight;
                document.querySelector('.feature-project').style.height = getHeight + 'px'
                window.addEventListener('resize', () => {
                    const getHeight = document.querySelector('.feature-project').clientHeight;
                    document.querySelector('.feature-project').style.height = getHeight + 'px';
                })

                // document.querySelector('.feature-project__left').style.height = document.querySelector('.feature-project__right .swiper-slide-active')?.clientHeight + 'px'

            }, 1000)
        }


    }, [])


    return (
        <StyledComponent offset={offset} className={'feature-project pb-80'}>
            <Container>
                <Row>
                    <Col sm={12} className={'d-flex slider-nav justify-content-between flex-wrap'}>
                        <Title color={'#fff'} text={'Subtitle'}/>
                        <ul className={'d-flex '}>
                            <li>Left</li>
                            <li>Right</li>
                        </ul>
                    </Col>
                </Row>

            </Container>
            <Container className={'p-0'} fluid>
                <Row>

                    <Col sm={12} className={'feature-project__right'}>
                        <Swiper loop={false}
                                spaceBetween={30}
                                slidesPerView={1}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                                allowTouchMove={false}
                                speed={900}
                            // pagination={{
                            //     type: "fraction",
                            // }}
                                navigation={{
                                    prevEl: '.feature-project .go-left',
                                    nextEl: '.feature-project .go-right ',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}

                            // autoplay={false}

                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{

                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    550: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },

                                }}>
                            {
                                data?.images?.list && data?.images?.list?.length>0 &&
                                data?.images?.list?.map((element,index)=>{
                                    console.log(element)
                                    return(
                                        <SwiperSlide key={index}>
                                            <div className="feature-project__slider">
                                                {/*<Link to={'#'}/>*/}
                                                <div className="feature-project__slider__img">
                                                    <Img src={element?.full_path}/>
                                                </div>

                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }

                        </Swiper>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background-color: #2B817D;
  padding-top: 50px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(60%);
    background-color: #FFFFFF;
  }

  .slider-nav {
    margin-bottom: 50px;
  }

  .feature-project__right {
    .feature-project__slider {
      position: relative;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
      }

      .add-to-fav {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        z-index: 2;

        svg {
          circle, path {
            transition: .6s ease;
          }
        }

        &:hover {
          svg {
            #Hover {
              cy: -13px;
            }

            #Path_1920 {
              stroke: ${text};
            }
          }
        }
      }

      &__img {
        padding-top: calc(312 / 370 * 100%);
        position: relative;
        overflow: hidden;
        border-radius: 12px;

        img {
          transform: scale(1.01);
        }
      }

      &:hover {
        .feature-project__slider__img {
          img {
            transform: scale(1.04);
          }

        }
      }
    }

    .swiper-slide {
      transition: all .6s ease;
    }

    @media (min-width: 991px) {
      .swiper-slide-active {
        width: 40% !important;
      }
    }
  }


  .swiper {
    padding-left: ${p => p.offset}px;
  }

  @media (max-width: 991px) {
    &:after {
      height: 40%;
    }
  }

  @media (max-width: 767px) {
    padding-top: 0;
    //padding-bottom: 100px;
    .container-fluid {
      //padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .title {
      width: 100%;
      margin-bottom: 40px;
    }
  }


`;

export default MyComponent;
