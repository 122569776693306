import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {hover, text} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";
import {Img} from "./Img";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'footer pt-100'}>
            {/*<Img src={'/images/static/noise-sm.jpg'}/>*/}
            <Container>
                <Row>
                    <Col sm={3}>
                        <a className={'call'} href={`tel:${data?.office_phone}`}>{data?.office_phone}</a>
                        <a className={'mail'} href={`mailto:${data?.contact_email}`}>{data?.contact_email}</a>
                    </Col>

                    <Col sm={{span: 8, offset: 1}} className={'footer__content'}>
                        <Row>
                            {data?.corporate_office_location && <Col sm={5}>
                                <h4>Corporate Office</h4>
                                <p>{ReactHtmlParser(data?.corporate_office_location)}</p>
                            </Col>}
                            {data?.factory_address && <Col sm={{span: 5, offset: 2}}>
                                <h4>Factory</h4>
                                <p>{ReactHtmlParser(data?.factory_address)}</p>
                            </Col>}


                            <Col sm={12}>
                                <div className="footer__content__menu">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/solutions">Solutions</Link></li>
                                        <li><Link to="/products">Products</Link></li>
                                        <li><Link to="/equipment">Equipment</Link></li>
                                        <li><Link to="/sustainability">Sustainability</Link></li>
                                        <li><Link to="/media">Media Center</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="footer-bottom">
                            <Row>
                                <Col sm={3}>
                                    <p>© {new Date().getFullYear()} Triple Apparel Limited. All Rights Reserved</p>
                                </Col>
                                <Col sm={{span: 4, offset: 1}}>
                                    <a href='https://dcastalia.com/' target='_blank'>Designed & Developed by
                                        Dcastalia <img src="/images/static/dcastalia.svg" alt=""/></a>
                                </Col>
                                <Col sm={4} className={"social"}>
                                    <ul>
                                        {data?.facebook && <li><a target={"_blank"} href={data?.facebook}><img
                                            src="/images/static/fb.svg" alt=""/></a></li>}
                                        {data?.linkedin && <li><a target={"_blank"} href={data?.linkedin}><img
                                            src="/images/static/in.svg" alt=""/></a></li>}
                                        {data?.instagram && <li><a target={"_blank"} href={data?.instagram}><img
                                            src="/images/static/insta.svg" alt=""/></a></li>}
                                        {data?.youtube && <li><a target={"_blank"} href={data?.youtube}><img
                                            src="/images/static/youtube.svg" alt=""/></a></li>}
                                        {data?.twitter && <li><a target={"_blank"} href={data?.twitter}><img
                                            src="/images/static/x.svg" alt=""/></a></li>}
                                    </ul>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  background-image: url('/images/static/noise-sm.jpg');
  background-repeat: repeat;
  background-color: #F1EFEA;
  background-blend-mode: screen;
  background-size: initial;
  @media (max-width: 500px) {
    background: none;
  }

  a {
    display: block;
  }

  .call {
    font-size: 32px;
    line-height: 40px;
    color: ${text};
    margin-bottom: 10px;
  }

  .mail {
    font-size: 16px;
    line-height: 20px;
    color: ${text};
  }

  .underline-btn {
    margin-top: 40px;
    font-size: 14px;
    text-transform: uppercase;
    color: ${text};
  !important;
    position: relative;
    width: fit-content;
    padding-bottom: 5px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background-color: ${text};
      transition: all 0.3s ease;

    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 0;
      opacity: 0;
      background-color: ${hover};
      transition: all 1s cubic-bezier(0.4, 0, 0, 1);
    }

    &:hover:before {
      height: 4px;
    }
  }

  h4 {
    color: #B7B4B0;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px;
  }

  p {
    color: ${text};
    //margin-bottom: 40px;
    font-size: 16px;
    line-height: 20px;
  }

  .footer__content {
    svg {
      margin-right: 20px;
    }

    .underline-btn {
      &:before, &:after {
        display: none;
      }

      span {
        position: relative;
        //display: block;
        color: ${text};
        padding-bottom: 5px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 100%;
          background-color: ${text};;
          transition: all 0.3s ease;
        }

        &:after {
          //content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 0;
          background-color: ${hover};
          transition: all 1s cubic-bezier(0.4, 0, 0, 1);
        }
      }

      &:hover span {
        &:before {
          height: 4px;
        }
      }
    }

    &__menu {
      margin-top: 60px;
      border-top: 1px solid rgba(183, 180, 176, 0.7);
      padding-top: 30px;
      padding-bottom: 30px;

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          a {
            font-size: 14px;
            line-height: 18px;
            color: ${text};
            display: block;
            padding: 5px 30px 5px 0;

          }

          &:nth-last-of-type(1) {
            margin-right: 0;
          }

        }
      }
    }
  }

  .footer-bottom {
    border-top: 1px solid rgba(183, 180, 176, 0.3);
    padding-top: 30px;
    padding-bottom: 28px;

    .row {
      align-items: center;
    }

    p, a {
      font-size: 12px;
      color: ${text};
      margin-bottom: 0;
    }

    img {
      margin-left: 10px;
    }

    ul {
      display: flex;

      li {
        a {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          border: 1px solid ${hover};
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding: 0;
          background-color: transparent;
          transition: background-color .5s ease;

          img {
            margin: 0;
          }

          &:hover {
            background-color: ${hover};

            img {
              filter: invert(95%) sepia(100%) saturate(44%) hue-rotate(256deg) brightness(234%) contrast(162%);
            }
          }
        }

        &:not(:nth-last-of-type(1)) {
          margin-right: 15px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .col-sm-3 {
      min-width: 100%;
    }

    .footer__content {
      min-width: 100%;
      margin: 50px 0 0 0;
    }

    .footer-bottom {
      .col-sm-3, .col-sm-6, .col-sm-2 {
        min-width: 33.333%;
        max-width: 33.333%;
        margin: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .footer-bottom {


      .col-sm-3, .col-sm-4 {
        min-width: 100%;
        max-width: 100%;
        margin: 0;
        order: 5
      }

      .social {
        order: 1;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 600px) {
    .underline-btn {
      margin-top: 30px;
    }

    p {
      margin-bottom: 20px;
    }

    //.col-sm-5:not(:nth-last-of-type(2)) {
    //  margin-bottom: 0px;
    //}
    .footer__content__menu {
      padding-bottom: 40px;
      margin-top: 20px;

      ul {
        flex-wrap: wrap;

        li {
          min-width: 100%;

          &:not(:nth-last-of-type(1)) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

`;

export default MyComponent;
