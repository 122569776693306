import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {hover, textMid, White} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'desc pt-200 pb-160'}>
            <img className="desc__shadow parallax-left" src="/images/static/overview.svg" alt=""/>
            <Container>
                <Row className={"desc__top"}>
                    <Col sm={9}>
                        <Title color={White} margin={'0 0 80px 0'} marginSm={' 0 0 40px 0'}
                               text={data?.section_data?.subtitle}/>
                        {reactHtmlParser(data?.section_data?.description)}
                    </Col>
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #231F20;
  position: relative;
  overflow: hidden;
  background-image: url(/images/dynamic/lather.jpg);
  background-repeat: repeat;
  z-index: 5;

  .desc__shadow {
    position: absolute;
    top: -30px;
    max-width: 90%;
  }

  .desc__top {
    p {
      color: ${hover};

      &:not(:nth-last-of-type(1)) {
        margin-bottom: 20px;
      }
    }
  }

  .desc__content {
    margin-top: 60px;
    gap: 0 120px;

    .col-sm-4 {
      max-width: calc(33.333333% - 120px);
      margin-bottom: 60px;
    }

    img {
      margin-bottom: 20px;
    }

    h4 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      font-family: ${textMid};
      margin-bottom: 20px;
      color: ${White};
    }

    p {
      color: #B7B4B0;
    }
  }

  @media (max-width: 991px) {
    .desc__content {
      gap: 0 20px;

      .col-sm-4 {
        max-width: calc(33.333333% - 20px);
      }
    }
  }

  @media (max-width: 575px) {
    .desc__shadow {
      top: 0;
    }

    .desc__content {
      .col-sm-4 {
        min-width: 100%;

        &:nth-last-of-type(1) {
          margin: 0;
        }
      }
    }
  }
`;

export default MyComponent;
