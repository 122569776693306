import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";
import {textMid, title} from "../../styles/globalStyleVars";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {toast} from "react-toastify";
import {postForm} from "../../api/redux/contact";


const ContactForm = ({data}) => {
    console.log(data)
    const store = useSelector((s) => s.contact);

    // form submission
    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitSuccessful},
        reset,
    } = useForm({
        mode: "all",
    });
    const success = (msg) =>
        toast.success(msg, {
            position: "top-right",
            autoClose: 4000,
            closeOnClick: true,
            progress: undefined,
        });

    const error = (msg) =>
        toast.error(msg, {
            position: "top-right",
            autoClose: 4000,
            closeOnClick: true,
            progress: undefined,
        });

    const dispatch = useDispatch();
    const apiEndPoint = apiEndPoints.FORM_SUBMIT;

    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append("form_id", "contact-form");
        formData.append("name", e?.email);
        formData.append("email", e?.email);
        formData.append("phone", e?.phone);
        formData.append("message", e?.message);
        dispatch(postForm([apiEndPoint, formData]));
    };

    useEffect(() => {
        if (!store?.formLoading) {
            if (isSubmitSuccessful) {
                success("Thank you for your message");
                reset();
            }
        }
    }, [isSubmitSuccessful, store]);


    return (
        <StyledContactForm className='pt-100 pb-200 horizontal-scroll' data-speed-right={50} data-speed-left={-50}>
            <Container>
                <Row>

                    <Col md={{span: 9, offset: 3}}>
                        <h4>{reactHtmlParser('Get in touch today!')}</h4>

                        <Form>
                            <input name={'spam_protector'} type='hidden'/>

                            <Form.Group className={"form-group"}>
                                <Form.Control
                                    autocomplete="off"
                                    className={
                                        errors?.location?.message ? "has-error" : ""
                                    }
                                    type="text"
                                    placeholder="Name*"
                                    {...register("name", {
                                        required: {
                                            value: true,
                                            message: "Please enter your name",
                                        },
                                    })}
                                />
                                <p className={'form-error'}>{errors.name?.message}</p>
                            </Form.Group>


                            <div className="form__phoneEmail">

                                <Form.Group className={"form-group"}>
                                    <Form.Control autocomplete="off" className={'has-error'}
                                                  type="number"
                                                  placeholder="Phone Number*"
                                                  {...register("phone", {
                                                      required: {
                                                          value: true,
                                                          message: "Please enter your phone",
                                                      },
                                                      pattern: {
                                                          value: /^01[0-9]{9}$/,
                                                          message: "Enter a valid 11 digit phone number",
                                                      }
                                                  })}
                                    />
                                    <p className={'form-error'}>{errors.phone?.message}</p>
                                </Form.Group>
                                <Form.Group className={"form-group"}>
                                    <Form.Control className={'has-error'}
                                                  autocomplete="off"
                                                  type="email"
                                                  placeholder="Email*"
                                                  {...register("email", {
                                                      required: {
                                                          value: true,
                                                          message: "Please enter your email",
                                                      },
                                                      pattern: {
                                                          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                          message: "Please enter a valid email address"
                                                      }
                                                  })}
                                    />
                                    <p className={'form-error'}>{errors.email?.message}</p>
                                </Form.Group>
                            </div>


                            <Form.Group className={"form-group"}>
                            <textarea {...register("message", {
                                required: {
                                    value: true,
                                    message: "Please enter your message",
                                },
                            })} placeholder={'Message*'} name="message" id="message" cols="" rows="4"
                                      style={{width: '100%'}}/>
                                <p className={'form-error'}>{errors.message?.message}</p>
                            </Form.Group>
                            <div onClick={handleSubmit(handleFormSubmit)}>
                                <Button text={'Submit Message'} color={'#323232'} background={'#E8E8E8'}/>
                            </div>

                        </Form>
                    </Col>
                </Row>


            </Container>
        </StyledContactForm>
    );
};

const StyledContactForm = styled.section`
  position: relative;
  background: #ffffff;

  &::before {
    position: absolute;
    content: '';
    background-image: url("/images/static/Contact-Text.svg");
    background-repeat: no-repeat;
    top: -200px;
    left: -50px;
    height: 100%;
    width: 100%;
  }

  @media (max-width: 767px) {
    &::before {
      display: none;
    }

    &.pt-200 {
      padding-top: 150px;
    }

    &.pb-200 {
      padding-bottom: 150px;
    }
  }

  .row {
    position: relative;
  }

  h4 {
    font-size: 32px;
    font-family: ${title};
    line-height: 36px;
    color: #3E3937;
    text-transform: uppercase;
    margin-bottom: 80px;
    //padding-bottom: 10px;
  }

  .form-control, textarea {
    background-color: white;
    border: none;
    border-bottom: 1px solid #B7B4B0;
    margin-bottom: 40px;
    color: #3E3937;
    padding-left: 0;
    font-size: 16px;
    line-height: 20px;
    padding-top: unset !important;
    padding-bottom: 20px !important;

    &:hover {
      outline: 0
    }
  ;

    &::placeholder {
      color: #B7B4B0;
    }

  }


  .form-control:focus {
    border-bottom: 1px solid #3E3937 !important;
  }


  .form-group {
    position: relative;
    display: flex;

    p {
      position: absolute;
      color: #EA2126;
      bottom: 17px;
      margin: 0px !important;
      font-size: 12px;
    }
  }

  .form__phoneEmail {
    display: flex;
    gap: 30px;

    .form-group {
      width: 50%;
      position: relative;


      input {
        width: 100% !important;
      }
    }


    @media (max-width: 767px) {
      gap: 0;
      flex-direction: column;
      width: 100%;

      input {
        width: 100% !important;
      }
    }
  }

  textarea {
    padding-top: 10px;
    padding-bottom: 80px;
    outline: none;
    box-shadow: none;
    //border: none;
  }

  .button-group {
    display: flex;
    gap: 40px;
    margin-bottom: 10px;

    .attach-cv {
      background-color: #323232;
      width: 178px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 28px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      label {
        padding-top: 5px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        font-family: ${textMid};
        color: #FFFFFF;
        margin-bottom: 0;
        cursor: pointer;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .dc-btn {
      width: 162px;
      height: 49px;
    }
  }

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(22, 31, 19, 0.2);
    margin-bottom: 40px;
    //color: #161F13;
    padding-left: 0;

    &::placeholder {
      //color: #161F13;
    }

    &:focus {
      border-bottom: 1px solid rgba(22, 31, 19, 0.2);
    }
  }


  p {
    color: #FFFDFB;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
`;

export default ContactForm;
