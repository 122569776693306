import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {hover, textMid, Transition, White} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    console.log('innovation', data)
    return (
        <StyledComponent className={"innovation pt-200 pb-160"}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <Title marginSm={"0 0 40px 0"} margin={"0 0 60px 0"}
                               text={data?.section_data?.subtitle}/>
                    </Col>

                    {
                        data?.posts?.list && data?.posts?.list?.length > 0 &&
                        data?.posts?.list?.map((e,i)=>{
                            return(
                                <Col sm={4} key={i}>
                                    <div className="innovation__single">
                                        <p>{reactHtmlParser(e?.data?.description)}</p>
                                        <h4>{e?.data?.title}</h4>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .col-sm-4 {
    margin-bottom: 30px;
  }

  .innovation__single {
    position: relative;
    overflow: hidden;
    padding-top: 100%;
    border: 1px solid ${hover};
    min-height: 100%;

    p {
      position: absolute;
      top: 40px;
      left: 40px;
      right: 40px;
      opacity: 0;
      transform: translateY(-30px);
      z-index: 2;
      transition: .6s ease;
    }

    h4 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
      font-family: ${textMid};
      bottom: 30px;
      left: 40px;
      right: 40px;
      position: absolute;
      z-index: 2;
      transition: .6s ${Transition};
    }

    &:after {
      content: '';
      position: absolute;
      background-color: ${hover};
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 0%;
      transition: .6s ${Transition};
    }

    &:hover {
      p {
        opacity: 1;
        transform: none;
        color: ${White};
      }

      h4 {
        color: ${White};
      }

      &:after {
        height: 100%;
      }
    }
  }

  @media (max-width: 1024px) {
    .col-sm-4 {
      min-width: 50%;
    }

    .innovation__single {
      p, h4 {
        left: 15px;
        right: 15px;
      }
    }
  }
`;

export default MyComponent;
