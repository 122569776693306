import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {hover, textMid} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={"ceo-msg pt-200 pb-200"}>
            <Container>
                <Row>
                    <Col sm={6}>
                        <Title fontSize={'32'} lineHeight={"36"} marginSm={"40px 0 40px 0"} margin={'60px 0 40px 0'}
                               text={data?.section_data?.subtitle}/>
                        {reactHtmlParser(data?.section_data?.description)}

                        <h4>{reactHtmlParser(data?.section_data?.short_desc)}</h4>
                        <h5>{reactHtmlParser(data?.section_data?.search_slug)}</h5>
                    </Col>

                    <Col sm={{span: 5, offset: 1}}>
                        <div className="ceo-msg__img">
                            <Img src={data?.images?.list?.[0]?.full_path}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F1EFEA;

  .container {
    border: 1px solid #FFFFFF;
    padding: 70px;
  }

  p {
    color: ${hover};
    margin-bottom: 20px;
  }

  h4 {
    margin-top: 40px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  h5 {
    font-size: 16px;
    line-height: 20px;
  }

  .ceo-msg__img {
    padding-top: calc(520 / 400 * 100%);
    position: relative;
  }

  @media (max-width: 768px) {
    .row {
      flex-direction: column-reverse;
    }

    .col-sm-5 {
      margin: 0;
      max-width: 50%;
    }

    .col-sm-6 {
      min-width: 100%;
    }
  }
  @media (max-width: 575px) {
    .container {
      border: 0;
      padding: 0px 15px;
    }

    .col-sm-5 {
      margin: 0;
      max-width: 100%;
    }

  }

`;

export default MyComponent;
