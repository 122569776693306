import {Route, Switch, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";
import {ParallaxImage, ParallaxImg, ParallaxItem, ParallaxLeft} from "./components/animations/Parallax";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";

// page imports
import About from './pages/about';
import Components from './pages/components';
import Home from './pages/home';
import Product from './pages/product';
import ProductDetail from './pages/product/detail';
import Contact from './pages/contact';
import Equipment from './pages/equipment';
import Media from './pages/media';
import MediaDetail from './pages/media/detail';
import Solutions from './pages/solutions';
import Sustainability from './pages/sustainability';
import KeyInnovation from './pages/innovation';
import Search from './pages/search';
import EquipmentDetail from './pages/equipment/detail';
import {SplitUp} from "./components/animations/TextAnimation";
import {useEffect, useLayoutEffect, useRef} from "react";
import Footer from "./components/Footer";
import Menu from "./components/Menu";

import {apiEndPoints} from "./api/network/apiEndPoints";
import {fetchFooter} from "./api/redux/footer";
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Loading} from "./components/Loading";
import PageTransition from "./components/PageTransition";
import {ApiParam} from "./api/network/apiParams";
import {fetchMenuData} from "./api/redux/menu";

function App() {
    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    const store = useSelector(store => store)
    const dispatch = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.GLOBAL_SETTING;
        dispatch(fetchFooter([api_url]));
    }, []);

    const getData = useSelector((state) => state.footer);
    const footerData = getData?.posts?.data;


    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "menu",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchMenuData([api_url, api_params]));
    }, [dispatch]);

    const getMenu = useSelector((state) => state.menu);

    const menu = getMenu?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "scroll-image");
    const leftImage = menu?.images?.list?.filter((f) => f?.is_left === "on");
    const rightImage = menu?.images?.list?.filter((f) => f?.is_right === "on");


    // smooth scroll init
    const el = useRef();
    useEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
            // effects: true // looks for data-speed and data-lag attributes on elements
            // speed: 2
        });

        return () => {
            smoother.kill();
        };

    }, [store]);

    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.add('in-home-page')
        } else {
            document.body.classList.remove('in-home-page')
        }


        if (location.pathname.startsWith('/product/')) {
            document.body.classList.add('menu-modify')
        } else if (location.pathname.startsWith('/equipment/')) {
            document.body.classList.add('menu-modify')
        } else {
            document.body.classList.remove('menu-modify')
        }
        setTimeout(() => {
            window.scroll(0, 0)
        }, 200)


    }, [location.pathname])

    useLayoutEffect(() => {
        setTimeout(() => {
            window.scroll(0, 0)
        }, 200)
    }, [])


    // animation functions
    ParallaxImg()
    ParallaxImage()
    ParallaxItem()
    // SplitUp()
    // ParallaxLeft()


    return (
        <>
            {store?.contact?.formLoading && <Loading/>}
            {store?.products?.loading && <Loading/>}
            {store?.media?.loading && <Loading/>}
            {store?.equipment?.loading && <Loading/>}


            <GlobalStyle/>
            <PageTransition/>
            <Menu leftImage={leftImage} rightImage={rightImage}/>
            {/*page transition */}
            <ToastContainer
                position="top-right"
                autoClose={4000}
                closeOnClick
                hideProgressBar={true}
            />
            <div className="page-transition">
                <img className={'logo'} src="/images/static/logo.svg" alt=""/>
            </div>
            <div className="App" ref={el} id="smooth-wrapper">
                <div id="smooth-content">
                    <Switch location={location} key={'location.pathname'}>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/components" component={Components}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/products" component={Product}/>
                        <Route exact path="/product/:slug" component={ProductDetail}/>
                        <Route exact path="/contact" component={Contact}/>
                        <Route exact path="/equipment" component={Equipment}/>
                        <Route exact path="/equipment/:slug" component={EquipmentDetail}/>
                        <Route exact path="/media" component={Media}/>
                        <Route exact path="/media/:slug" component={MediaDetail}/>
                        <Route exact path="/solutions" component={Solutions}/>
                        <Route exact path="/search" component={Search}/>
                        <Route exact path="/sustainability" component={Sustainability}/>
                        <Route exact path="/key-innovation/:slug" component={KeyInnovation}/>
                        <Route component={Error}/>
                    </Switch>
                    <Footer data={footerData}/>
                </div>
            </div>
        </>
    );
}

export default App;
