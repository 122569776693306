import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "./Title";
import Button from "./Button";
import {hover} from "../styles/globalStyleVars";
import {Img} from "./Img";
import ReactHtmlParser from "react-html-parser";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const MyComponent = ({icon, data}) => {

    useEffect(() => {
        //
        // gsap.utils.toArray(".about .years").forEach((item, i) => {
        //     gsap.to(item, {
        //         x: '-20%',
        //         // ease: "none",
        //         scrollTrigger: {
        //             trigger: item,
        //             // markers: true,
        //             scrub: true
        //         }
        //     });
        // });
        // return () => {
        //     ScrollTrigger.getAll().forEach(trigger => {
        //         trigger.kill(); // Remove all ScrollTriggers
        //     });
        // };

        // console.log('garments__slide__items', document.querySelector('.garments__slide__items__single').clientWidth)
    }, [data])

    return (
        <StyledComponent className={"about pt-200 pb-200"}>
            <img className={'years parallax-left'}
                 src={data?.images?.list?.find(f => f.is_top === 'on')?.full_path ? data?.images?.list?.find(f => f.is_top === 'on')?.full_path : "/images/dynamic/year.svg"}
                 alt="year"/>
            <Container>
                <Row>
                    <Col sm={6}>
                        <Title marginSm={"60px 0 20px 0"} margin={'80px 0 20px 0'}
                               text={data?.section_data?.subtitle}/>
                        <div className="about__content__desc">
                            {ReactHtmlParser(data?.section_data?.description)}
                            <Button marginSm={"40px 0 0 0"} src={'/about'} margin={"60px 0 0 0"}/>
                        </div>
                    </Col>
                    <Col sm={{span: 5, offset: 1}}>
                        <div className="about__content__img ">
                            <div className="parallax-img">
                                <Img src={data?.images?.list?.find(f => f.is_right === 'on')?.full_path}/>
                            </div>


                            {icon && <img className={"about__content__img__shadow"} src="/images/static/cloth-red.png"
                                          alt=""/>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background-image: url('/images/static/noise-sm.jpg');
  background-repeat: repeat;
  background-color: #F1EFEA;
  background-blend-mode: screen;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    height: 50px;
    top: -50px;
    width: 105%;
    box-shadow: rgb(0 0 0 / 37%) 0px 21px 26px -4px;
    right: 0px;
  }

  @media (max-width: 500px) {
    background: none;
  }
  //&:before {
  //  content: '';
  //  position: absolute;
  //  background: linear-gradient(180deg, rgba(62, 57, 55, 0.3) 10%, rgba(255, 255, 255, 0) 100%);
  //  top: 0;
  //  width: 100%;
  //  z-index: 99;
  //  height: 200px;
  //}

  .years {
    position: absolute;
    top: 0;
    left: 20%;
    //right: 0px;
    //margin: auto;
    max-width: 90%;
  }

  .about__content {
    &__desc {
      padding-left: 150px;
      color: ${hover};

      p {
        color: ${hover};

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .about__content__img {
    padding-top: calc(620 / 470 * 100%);
    position: relative;
    //overflow: hidden;

    &__shadow {
      position: absolute;
      z-index: 2;
      left: -70px;
      bottom: -80px;
      height: 250px;
    }
  }

  @media (max-width: 991px) {
    .row {
      flex-direction: column-reverse;
    }

    .col-sm-6 {
      min-width: 100%;
    }

    .col-sm-5 {
      margin: 0;
    }

    .about__content__img__shadow {
      height: 120px;
      bottom: -40px;
      left: -30px;
    }
  }

  @media (max-width: 575px) {
    .years {
      height: 80px;
    }

    .about__content__desc {
      padding-left: 0;
    }
  }

`;

export default MyComponent;
