import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {ApiParam} from "../../api/network/apiParams";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {fetchHomeData} from "../../api/redux/home";
import BannerSlider from "../../components/home/BannerSlider";
import About from "../../components/About";
import Capablity from "../../components/home/Capablity";
import Garments from "../../components/home/Garments";
import BIgBanner from "../../components/home/BIgBanner";
import Solutions from "../../components/home/Solutions";
import Technologies from "../../components/home/Technologies";
import Insights from "../../components/home/Insights";
import {SplitUp} from "../../components/animations/TextAnimation";
import {ParallaxImage, ParallaxImg, ParallaxItem} from "../../components/animations/Parallax";


const Home = () => {
    const dispatch = useDispatch();

    SplitUp()
    // ParallaxImg()
    // ParallaxImage()
    // ParallaxItem()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "home",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchHomeData([api_url, api_params]));
    }, []);

    const getData = useSelector((state) => state.home);

    // get all section
    const banner = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "banner")?.posts?.list;
    const about = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "about");
    const production = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "production-capability");
    const productCategoryTitle = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "product-category");
    const cta = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "cta");
    const productCategory = getData?.posts?.product_category;
    const solution = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "our-solutions");
    const featureEquipment = getData?.posts?.feature_equipment;
    const featureBlog = getData?.posts?.featured_blog;


    // SplitUp(getData)

    useEffect(() => {
        setTimeout(() => {
            window.scroll(0, 0)
        }, 200)
    }, [])
    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Triple Apparel Limited | Pioneers of elevated style</title>
                <meta
                    content="Triple Apparels Ltd is the latest addition to the Partex Star family, a household name in Bangladesh. We are well poised to position ourselves as one of the largest global apparel manufacturers with world-class capabilities and standards."
                    name="description"/>
            </Helmet>
            <BannerSlider data={banner}/>
            <About data={about}/>
            <Capablity data={production}/>
            <Garments data={productCategory}/>
            <BIgBanner shadow linkText={"LEARN MORE"} link={'/sustainability'}
                       imgSm={cta?.images?.list?.find(f => f?.is_mobile === 'on')?.full_path}
                       img={cta?.images?.list?.find(f => f?.is_desktop === 'on')?.full_path}
                       title={cta?.section_data?.subtitle}
                       text={cta?.section_data?.short_desc}/>
            <Solutions data={solution}/>
            <Technologies data={featureEquipment}/>
            <Insights data={featureBlog}/>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default Home;
