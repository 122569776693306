import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import Button from "../Button";
import SingleProduct from "../SingleProduct";
import {title} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={"related pb-200"}>
            <Container>
                <Row>
                    <Col sm={12} className={"d-flex justify-content-between flex-wrap"}>
                        {/*<Title marginSm={"0 0 30px 0"} margin={"0 0 60px 0"} fontSize={"32"} lineHeight={"36"}*/}
                        {/*       text={"Related Products"}/>*/}
                        <h2>Related Products</h2>
                        <Button marginSm={'0px 0 40px 0'} text={"View All Products"} src={"/products"}/>
                    </Col>
                    {
                        data?.map((item) => {
                            return (
                                <Col sm={3}>
                                    <SingleProduct slug={`/product/${item?.product_data?.slug}`}
                                                   cat={item?.product_data?.category_title}
                                                   title={item?.product_data?.title}
                                                   img={item?.images?.list?.[0]?.full_path}/>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  h2 {
    font-size: 32px;
    line-height: 36px;
    margin: 0 0 60px 0;
    font-family: ${title};
    text-transform: uppercase;
    @media (max-width: 767px) {
      margin-bottom: 40px !important;
    }
  }

  @media (max-width: 768px) {
    .col-sm-3 {
      min-width: 50%;
      margin-bottom: 40px;
    }
  }
`;

export default MyComponent;
