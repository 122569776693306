import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Title from "../components/Title";
import Button from "../components/Button";
import InnerBanner from "../components/InnerBanner";
import {SplitText} from "gsap/SplitText";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const MyComponent = () => {
    gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrollTrigger);
    const location = useLocation();

    useEffect(() => {
        new SplitText(".up", {type: "lines", linesClass: "lineChild"});
        new SplitText(".up", {type: "lines", linesClass: "lineParent"});

        if (SplitText) {
            gsap.set(".lineChild", {yPercent: 100, autoAlpha: 0});

            ScrollTrigger.batch(".lineChild", {
                start: "-100% bottom",
                interval: 0.3,
                onEnter: elements => {
                    gsap.to(elements, {
                        autoAlpha: 1,
                        yPercent: 0,
                        // stagger: 0.25,
                        duration: 1,
                        overwrite: true
                    });
                },
                onLeaveBack: elements => {
                    gsap.set(elements, {
                        autoAlpha: 0,
                        yPercent: 100,
                        overwrite: true
                    });
                }
            });
        }
        return (() => {
            ScrollTrigger.killAll()
        })
    });

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Title</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner/>

                <h1 className={"up"} style={{marginBottom: "100px"}}>Lorem ipsum dolor sit amet, consectetur
                    adipisicing
                    elit. Et,
                    sapiente! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et,
                    sapiente!</h1>
                <p className={"up"} style={{marginBottom: "100px"}}>Our passion for innovation fuels a relentless
                    pursuit of excellence,
                    pushing us to develop gear that Our passion for innovation fuels a relentless pursuit of excellence,
                    pushing us to develop gear that
                    not only enhances performance but sets new standards for comfort and style.</p>
                <h2 className={"up"} style={{marginBottom: "100px"}}>Our mantra, "Incomparable, Innovative,
                    Wellbeing,"
                    encapsulates the
                    core values that drive every
                    facet of Triple Apparel. We believe in <br/> providing athletes and enthusiasts alike with
                    unparalleled
                    apparel that transcends the ordinary, reflecting a dedication to innovation that knows no
                    bounds.</h2>

                <p className={"ups"} style={{marginBottom: "100px"}}> xxxxxxxxxx Our passion for innovation fuels a
                    relentless
                    pursuit of excellence,
                    pushing us to develop gear that Our passion for innovation fuels a relentless pursuit of excellence,
                    pushing us to develop gear that
                    not only enhances performance but sets new standards for comfort and style.</p>
                <h2 className={"up"} style={{marginBottom: "100px"}}>Our mantra, "Incomparable, Innovative, Wellbeing,"
                    encapsulates the
                    core values that drive every
                    facet of Triple Apparel. We believe in <br/> providing athletes and enthusiasts alike with
                    unparalleled
                    apparel that transcends the ordinary, reflecting a dedication to innovation that knows no
                    bounds.</h2>

                <p className={"up"} style={{marginBottom: "100px"}}>Our passion for innovation fuels a relentless
                    pursuit of excellence,
                    pushing us to develop gear that Our passion for innovation fuels a relentless pursuit of excellence,
                    pushing us to develop gear that
                    not only enhances performance but sets new standards for comfort and style.</p>

                <Button/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
    .ups {
        overflow: hidden;
        height: 90px;
    }
`;

export default MyComponent;
