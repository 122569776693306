import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchSustainabilityData} from "../../api/redux/sustainability";
import SustainBanner from "../../components/sustainability/SustainBanner";
import CsrOverView from "../../components/sustainability/CsrOverView";
import CsrSlider from "../../components/sustainability/CsrSlider";
import Partners from "../../components/Partners";
import {SplitUp} from "../../components/animations/TextAnimation";
import {ParallaxImage, ParallaxImg, ParallaxItem} from "../../components/animations/Parallax";

const MyComponent = () => {
    const dispatch = useDispatch();

    SplitUp()
    ParallaxImg()
    ParallaxImage()
    ParallaxItem()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "sustainability",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchSustainabilityData([api_url, api_params]));
    }, [dispatch]);

    const getData = useSelector((state) => state.sustainability);
    const sustainabilityBanner = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "sustainability-banner");
    const gallery = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "gallery");
    const care = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "we-care");

    console.log(gallery)
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Sustainability | Triple Apparel Limited</title>
                <meta
                    content="Triple Apparels Ltd is the latest addition to the Partex Star family, a household name in Bangladesh. We are well poised to position ourselves as one of the largest global apparel manufacturers with world-class capabilities and standards."
                    name="description"/>
            </Helmet>

            <StyledComponent className={'sustain'}>
                <SustainBanner data={sustainabilityBanner}/>
                <CsrOverView data={gallery}/>
                <Partners data={care} title={'We Care'}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
