import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Button from "../Button";
import {hover, text} from "../../styles/globalStyleVars";
import SingleEquipment from "../SingleEquipment";
import SingleProduct from "../SingleProduct";

const MyComponent = ({data}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        // Filter data based on search query
        const filtered = data?.filter(product =>
            product.product_data.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchQuery, data]);


    return (
        <StyledComponent className={'search'}>
            <Container>
                <Row>
                    <Col sm={12} className={"search__input"}>
                        <input
                            placeholder={"Search for products"}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <p>Search</p>
                    </Col>
                </Row>

                <Row className={"search__result"}>
                    {searchQuery !== '' && filteredData?.length > 0 ? filteredData?.map((e, i) => (
                        <Col sm={4} md={3} key={i}>
                            <SingleProduct
                                cat={e.product_data.category_title}
                                title={e.product_data.title}
                                img={e.images.list[0]?.full_path}
                                slug={`/product/${e.product_data.slug}`}
                            />
                        </Col>
                    )) : <Col><p>Try searching for something</p></Col>}
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding: 100px 0;

  .search__input {
    position: relative;

    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid ${text};
      outline: none;
      padding-bottom: 15px;

      &::placeholder {
        color: rgba(62, 57, 55, 0.25);
        font-weight: bold;
      }
    }

    p {
      position: absolute;
      right: 15px;
      bottom: 15px;
      cursor: pointer;
      letter-spacing: 1px;
      transition: .4s ease;

      &:hover {
        color: ${hover};
      }
    }
  }

  .search__result {
    margin-top: 60px;
    min-height: 40vh;
  }

  .col-sm-4 {
    margin-bottom: 40px;
  }
`;

export default MyComponent;
