import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import Button from "../Button";
import SingleMedia from "../SingleMedia";
import {White} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={"insights pt-200 pb-200"}>
            <Container>
                <Row>
                    <Col sm={12}
                         className={"insights__title d-flex justify-content-between align-items-center flex-wrap"}>
                        <Title fontSize={"32"} lineHeight={"36"} marginSm={"0px 0 40px 0"} margin={"0 0 50px 0"}
                               text={"Industry Insights"}/>
                        <Button marginSm={"0px 0 40px 0"} src={"/media"} margin={"0 0 50px 0"} text={"View All"}/>
                    </Col>

                    <div className="insights__list">
                        {data?.map(i => (
                            <Col key={i?.data?.id} sm={4}>
                                <SingleMedia title={i?.data?.title} date={"19"}
                                             year={"2020"}
                                             month={"August"} link={`/media/${i?.data?.slug}`} type={"News"}
                                             img={i?.images?.list?.find(f => f?.thumb === "on")?.full_path}/>
                            </Col>
                        ))}
                    </div>

                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F1EEE9;

  .blog-single__inner .noise {
    background-color: #F1EEE9;
  }

  .insights__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .col-sm-4 {
      &:nth-child(n+4) {
        display: none;
      }

      &:nth-child(1) {
        .blog-single__inner__content:after {
          height: 0;
        }

        .noise {
          height: 0;
        }

        .blog-single__inner__content__top {
          h4 {
            //opacity: 0;
            //transform: translateY(-20px);
            color: ${White};;
          }

          p {
            transform: none;
            //opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: ${White};

          h2 {
            color: ${White};
          }

          p {
            color: ${White};

            span {
              color: ${White};
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .blog-single__inner__content__top h4 {
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media (max-width: 768px) {
    .col-sm-4 {
      min-width: 50%;

      &:nth-child(n+3) {
        display: none;
      }
    }
  }

  @media (max-width: 575px) {
    .col-sm-4 {
      display: block !important;

      &:not(:nth-last-of-type(1)) {
        margin-bottom: 40px;
      }
    }
  }
`;

export default MyComponent;
