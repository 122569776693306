import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Select from 'react-select'
import ReactHtmlParser from "react-html-parser";
import {textMid} from "../../styles/globalStyleVars";

const MyComponent = ({data, id,}) => {
    const [offset, setoffset] = useState(90)
    const [key, setKey] = useState(1);


    const options = [
        {value: 1, label: 'Head Office'},
        {value: 2, label: 'Factory'},
        {value: 3, label: 'Second Office'},
        {value: 4, label: 'Third Office'},
    ]

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : '#221F1F',
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            margin: 0,
            fontSize: 12,
            cursor: 'pointer',
            paddingLeft: 25
        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? '#191818' : '#C4C4C4',
            margin: 0,
            borderRadius: 5,
            fontSize: 12,

        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#C4C4C4' : '#FFF',
            borderRadius: 0,
            fontWeight: '600',
            color: '#FFF',
            fontSize: 12,

        }),

    };


    const [selected, setSelected] = useState(1);
    const handleChange = event => {
        setSelected(event.value);
        // 80
        // document.querySelector('#controlled-tab-example-tab-tab'+event.value).click();
    };

    useEffect(() => {
        const containerElement = document.querySelector('.container');

        if (!containerElement) {
            console.error('Container element not found');
            return;
        }

        setoffset(containerElement.offsetLeft);

        const tabElement = document.querySelector('#controlled-tab-example-tab-tab' + selected);

        if (!tabElement) {
            console.error('Tab element not found');
            return;
        }

        tabElement.click();

        const handleLoad = () => {
            console.log('Window loaded');
            setoffset(containerElement.offsetLeft);
            tabElement.click();
        };

        const handleResize = () => {
            console.log('Window resized');
            setoffset(containerElement.offsetLeft);
        };

        window.addEventListener('load', handleLoad);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('resize', handleResize);
        };
    }, [selected]);


    return (

        <StyledComponent getoffset={offset} id={`${id ? id : 'AddressTab'}`} offset={offset}
                         className={`addresstab pb-100`}>
            <Container fluid>
                <Row>
                    <div className="tab_container">
                        <Col md={12}>
                            <div className="formobile">
                                <Select className={'find-retainer-filter__select'}
                                        value={selected} styles={customStyles} onChange={handleChange}
                                        options={options}/>
                            </div>

                            <Tabs
                                id="controlled-tab-example"
                                activeKey={window.innerWidth > 767 ? key : selected}
                                onSelect={(k) => setKey(k)}
                                className="mb-3"
                            >
                                {
                                    data?.posts?.list?.map((item, index) => {
                                        return (
                                            <Tab eventKey={index + 1} title={item?.data?.title}>
                                                <div className="custom_pad row m-0">
                                                    <Col className={'pl-0'} md={3}>
                                                        <h4>{item?.data?.title}</h4>
                                                    </Col>
                                                    <Col md={3}>
                                                        {ReactHtmlParser(item?.data?.subtitle)}
                                                    </Col>
                                                    <Col md={3}>
                                                        <ul className={'list'}>
                                                            {ReactHtmlParser(item?.data?.short_desc)}
                                                        </ul>
                                                    </Col>
                                                    <Col md={3}>
                                                        <ul className={'list'}>
                                                            {/*<li><a href="mailto:admin@tal.com">admin@tal.com</a></li>*/}
                                                            {/*<li><a href="mailto:info@tal.com">info@tal.com</a></li>*/}
                                                            {ReactHtmlParser(item?.data?.description)}
                                                        </ul>
                                                    </Col>
                                                </div>
                                                <Row>

                                                    <div className="clearfix"/>
                                                    <Col md={12} className={'google_map'}>
                                                        {
                                                            item?.images?.[0]?.short_title ? <div className={'map_img'}>
                                                                <iframe
                                                                    title={"google map"}
                                                                    src={item?.images?.[0]?.short_title}
                                                                    style={{border: 0}}
                                                                    allowFullScreen=""
                                                                    loading="lazy"
                                                                    referrerPolicy="no-referrer-when-downgrade"/>
                                                            </div> : <a target={"_blank"} href={'#'}><img
                                                                src={item?.images?.[0]?.full_path} alt=""/></a>
                                                        }


                                                    </Col>
                                                </Row>
                                            </Tab>
                                        )
                                    })
                                }
                            </Tabs>
                        </Col>
                    </div>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  padding-top: 80px;
  overflow: hidden;

  @media (max-width: 992px) {
    overflow: hidden;
  }
  @media (max-width: 767px) {
    .google_map {
      padding: 0;
    }
  }

  .hotline {
    img {
      height: 50px;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .map_img {
    position: relative;
    padding-top: calc(560 / 1268 * 100%);


    iframe {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  .formobile {
    display: none;
    @media (max-width: 767px) {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  &:after {
    height: 80vh;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-image: url('/images/static/noise-sm.jpg');
    background-repeat: repeat;
    background-color: #F1EFEA;
    background-blend-mode: screen;
    width: 100%;
    z-index: 1;
    content: "";
    @media (max-width: 500px) {
      background: none;
    }
    @media (max-width: 767px) {
      height: calc(100vh + 10px);
    }
  }

  .container-fluid {
    position: relative;
    z-index: 2;
    margin: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: ${props => props.getoffset ? props.getoffset + 15 + 'px' : ''};

    .google_map {
      padding-right: 0;
    }

    @media (max-width: 767px) {
      padding: 0 15px !important;
    }

  }

  .custom_pad {
    padding-right: ${props => props.getoffset ? props.getoffset + 15 + 'px' : ''};
  }

  .tab_container {
    width: 100%;

    .nav-tabs {
      border: none;
      margin-right: ${props => props.getoffset ? props.getoffset + 15 + 'px' : ''};
      display: flex;
      justify-content: space-between;
      gap: 30px;
      border-bottom: 1px solid #FFFFFF;

      @media (max-width: 767px) {
        display: none;
      }

      .nav-item {
        border: none;
        flex: 0 0 calc(25% - 30px);


        button {
          width: 100%;
          text-align: left;
          border: none;
        }

        &:last-child {
          margin-right: 0;
        }

        .nav-link {
          height: 40px;
          font-size: 20px;
          font-weight: 500;
          font-family: ${textMid};
          color: #B7B4B0;
          padding: 0;
          line-height: 24px;
          position: relative;
          transition: 0.8s all cubic-bezier(0.4, 0, 0, 1);

          &:after {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            content: "";
            transition: 0.8s all cubic-bezier(0.4, 0, 0, 1);
            transform: scale(0);
            z-index: -1;
          }

          &.active {
            color: #3E3937;
            border-bottom: 2px solid #3E3937;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            background: transparent;

          }
        }

      }
    }

    .google_map {
      margin-top: 60px;
    }

    .tab-pane {
      padding-top: 60px;
      @media (max-width: 767px) {
        padding-top: 40px;
        .col-md-3, .col-md-4 {
          margin-bottom: 20px;
        }
      }

      p, a {
        color: #3E3937;
        font-size: 16px;
        font-weight: book;
        line-height: 20px;
        display: block;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      h4 {
        color: #3E3937;
        font-size: 28px;
        font-weight: 500;
        font-family: ${textMid};
        line-height: 32px;
      }
    }
  }

  .css-13cymwt-control {
    border-radius: 0;

    .css-1u9des2-indicatorSeparator {
      display: none;
    }
  }
`;

export default MyComponent;
