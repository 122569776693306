import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import List from "../../components/product/List";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchProduct} from "../../api/redux/products";
import {useHistory, useLocation} from "react-router-dom";
import {SplitUp} from "../../components/animations/TextAnimation";
import {ParallaxImage, ParallaxImg, ParallaxItem} from "../../components/animations/Parallax";

const MyComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [activeCat, setActiveCat] = useState(0)
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation()

    SplitUp()
    ParallaxImg()
    ParallaxImage()
    ParallaxItem()

    // api call
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                let api_url = apiEndPoints.GET_PRODUCTS;

                let api_params = {
                    [ApiParam.CATEGORY_ID]: activeCat ? activeCat : 0,
                    [ApiParam.IMAGE]: "yes",
                    [ApiParam.POST]: "yes",
                };

                await dispatch(fetchProduct([api_url, api_params]));
                setIsLoading(false);
            } catch (err) {
                // Handle errors if needed
                console.error("Error fetching products:", err);
                setIsLoading(false);
            }
        }
        fetchData();
    }, [activeCat]);

    // get the query param

    const getData = useSelector((state) => state.products);
    const banner = getData?.posts?.project_data;
    const products = getData?.posts?.data;
    const categories = getData?.posts?.filter?.category;

    const handleCat = useCallback((e, a) => {
        setActiveCat(e);
        // Update the URL with the new activeCat value
        history.push(`?category=${a ? a : 'all'}`);
    }, [history]);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Product | Triple Apparel Limited</title>
                <meta
                    content="Triple Apparels Ltd is the latest addition to the Partex Star family, a household name in Bangladesh. We are well poised to position ourselves as one of the largest global apparel manufacturers with world-class capabilities and standards."
                    name="description"/>
            </Helmet>

            <StyledComponent className={'product-page'}>
                <InnerBanner title={banner?.page_data?.subtitle} text={banner?.page_data?.short_desc}
                             img={banner?.images?.list?.[0]?.full_path}/>
                <List loading={getData?.loading} activeCat={activeCat} handleCat={handleCat} categories={categories}
                      products={products}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
