import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import Title from "../Title";
import Button from "../Button";
import {White} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({img, title, link, linkText, text, shadow, imgSm}) => {
    return (
        <StyledComponent className={"big-banner"}>
            <div className="big-banner__inner">
                <Img srcSm={imgSm || img} src={img}/>
                <Container>
                    <Row>
                        <Col sm={5}>
                            <Title classname={"parallax-item"} marginSm={'0 0 20px 0'} color={White} text={title}/>
                        </Col>
                        <Col sm={{span: 4, offset: 3}} className="big-banner__inner__desc">
                            {shadow && <img className={"parallax-item"} src={'/images/static/leaf.svg'} alt={"leaf"}/>}

                            <p>{reactHtmlParser(text)}</p>
                            <Button src={link} margin={'60px 0 0 0'} text={linkText} marginSm={"40px 0 0 0"}
                                    color={White}/>

                        </Col>

                    </Row>
                </Container>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  //background-color: rgb(241, 239, 234);

  .big-banner__inner {
    position: relative;
    padding-top: calc(768 / 1366 * 100%);
    overflow: hidden;

    .container {
      position: absolute;
      bottom: 120px;
      left: 0;
      right: 0px;
      margin: auto;
    }

    p {
      color: ${White};
    }

    &__desc {
      position: relative;

      img {
        position: absolute;
        left: -50%;
        top: -90%;
      }

      p, h2 {
        position: relative;
        z-index: 2;
      }
    }
  }

  @media (min-width: 1700px) {
    .big-banner__inner__desc img {
      left: -30%;
    }
  }

  @media (max-width: 991px) {
    .big-banner__inner {
      padding-top: 120px;
      padding-bottom: 60px;

      .container {
        position: initial;

      }

      &__desc img {
        top: -40%;
      }
    }
  }

  @media (max-width: 767px) {
    .col-sm-5 {
      min-width: 100%;
    }

    .title {
      position: relative;
      z-index: 2;
    }

    .big-banner__inner__desc {
      min-width: 100%;
      margin: 0;

      img {
        left: 0;
        top: -100%;
        height: 180px;
      }
    }
  }

`;

export default MyComponent;
