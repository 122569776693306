import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {text} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";

const MyComponent = ({img, cat, title, slug}) => {
    return (
        <StyledComponent className={"list__single"}>
            <Link to={slug}/>
            <div className="list__single__img">
                <Img transition src={img}/>
            </div>
            <div className="list__single__content">
                <p>{cat}</p>
                <h2>{title}</h2>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  width: 100%;

  a {
    position: absolute;
    inset: 0;
    z-index: 2;
  }

  .list__single__img {
    padding-top: calc(360 / 270 * 100%);
    position: relative;
    overflow: hidden;
  }

  .list__single__content {
    margin-top: 20px;

    p {
      font-size: 16px;
      line-height: 20px;
      color: #b7b4b0;
      margin-bottom: 8px;
    }

    h2 {
      font-size: 20px;
      font-weight: 500;
      color: ${text};

    }
  }

  &:hover {
    .list__single__img img {
      transform: scale(1.04);
    }
  }

  @media (max-width: 550px) {
    .list__slider ul li img {
      margin-right: 8px;
    }
  }
`;

export default MyComponent;
