import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Search from "../../components/search/Search";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchSearchData} from "../../api/redux/search";
import {SplitUp} from "../../components/animations/TextAnimation";
import {ParallaxImage, ParallaxImg, ParallaxItem} from "../../components/animations/Parallax";

const MyComponent = () => {

    const dispatch = useDispatch();
    SplitUp()
    ParallaxImg()
    ParallaxImage()
    ParallaxItem()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "search",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchSearchData([api_url, api_params]));
    }, [dispatch]);

    const getData = useSelector((state) => state.search);

    //refactor

    const banner = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "search-banner");
    const products = getData?.posts?.all_product?.list;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Search products | Triple Apparel Limited</title>
                <meta
                    content="Triple Apparels Ltd is the latest addition to the Partex Star family, a household name in Bangladesh. We are well poised to position ourselves as one of the largest global apparel manufacturers with world-class capabilities and standards."
                    name="description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    img={banner?.images?.list?.filter((f) => f?.is_desktop === "on")?.[0]?.full_path}
                    title={banner?.section_data?.subtitle}
                    imgSm={banner?.images?.list?.filter((f) => f?.is_mobile === "on")?.[0]?.full_path}/>
                <Search data={products}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
