import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import Button from "../Button";
import {Img} from "../Img";
import {textMid, title, Transition, White} from "../../styles/globalStyleVars";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(100)

    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])

    useEffect(() => {
        if (window?.innerWidth > 992 && data?.length > 0) {
            gsap.timeline({
                scrollTrigger: {
                    id: `w`,
                    trigger: '.garments',
                    start: `top ${window.innerWidth > 1900 ? '-250' : '-100'}`,
                    pin: true,
                    // end: "700",
                    scrub: 2,
                    toggleActions: 'play none none reverse',
                }
            }).to('.garments__slide__items', {
                ease: "linear",
                x: '-100%',
                duration: 1,
            })
        }
        // console.log('garments__slide__items', document.querySelector('.garments__slide__items__single').clientWidth)
        return (() => {
            ScrollTrigger.killAll('w')
        })
    }, [data])

    useEffect(() => {
        let getAlla = document.querySelectorAll('a')
        getAlla.forEach(e => {
            e.addEventListener('click', () => {
                // const kill = ScrollTrigger.getById('whois');
                // if (kill) {
                //     kill.kill()
                // }
                ScrollTrigger.killAll()
            })
        })
    })


    return (
        <StyledComponent className={'garments pt-200 pb-200'}>
            <div className="garments__slide">
                <Container>
                    <div className="garments__slide__top d-flex justify-content-between">
                        <Title noAnim marginSm={"0 0 40px 0"} margin={"0 0 40px 0"}
                               text={'Our Garments Range'}
                               fontSize={'32'}
                               lineHeight={'36'}/>
                        <Button marginSm={"0 0 40px 0"} margin={"0 0 40px 0"} src={'/products'}
                                text={"View All Products"}/>
                    </div>
                </Container>

                <div className="garments__slide__items" style={{paddingLeft: offset + 15 + 'px'}}>
                    {data?.length > 0 && data?.map((e, i) => (
                        <div className="garments__slide__items__single">
                            <Link
                                to={e?.is_details == 1 ? `/key-innovation/${e?.slug}` : `/products?category=${e?.slug}`}/>
                            <div className="garments__slide__items__single__inner">
                                <Img src={e?.images?.list?.[0]?.full_path}/>
                                <h3>{i + 1}</h3>
                                <div className="content">
                                    <h4>{e?.title}</h4>
                                    <p>View Products</p>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>


        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #F1EFEA;

    .garments__slide__items {
        display: flex;
        gap: 0 30px;

        &__single {
            min-width: calc(25% - 15px);
            max-width: calc(25% - 15px);
            position: relative;

            a {
                position: absolute;
                inset: 0;
                z-index: 5;
            }

            &__inner {
                padding-top: calc(380 / 270 * 100%);
                position: relative;
                overflow: hidden;

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transition: .6s ${Transition};
                    background-color: rgba(0, 0, 0, 0.4);
                    height: 0;
                }

                h3 {
                    font-size: 120px;
                    line-height: 60px;
                    color: rgba(0, 0, 0, 0.3);
                    font-family: ${title};
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    bottom: 50px;
                    width: fit-content;
                }

                .content {
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    padding: 0 20px 50px 0;
                    bottom: 0;
                    text-align: center;
                    transform: translateY(20px);
                    transition: .6s ${Transition};

                    h4 {
                        font-size: 28px;
                        line-height: 32px;
                        font-weight: 500;
                        margin-bottom: 13px;
                        color: ${White};
                        font-family: ${textMid};
                    }

                    p {
                        font-size: 12px;
                        line-height: 16px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        color: ${White};
                        opacity: 0;
                        transition: .3s ${Transition};
                    }
                }
            }

            &:hover {
                .garments__slide__items__single__inner {
                    &:after {
                        height: 100%;
                    }

                    .content {
                        transform: none;

                        p {
                            opacity: 1;
                        }
                    }
                }

            }
        }
    }

    @media (max-width: 991px) {
        .garments__slide__top {
            flex-wrap: wrap;

            .title {
                min-width: 100%;
            }
        }


        .garments__slide__items {
            flex-wrap: wrap;
            padding: 0 15px;

            &__single {
                min-width: calc(50% - 15px);
                margin-bottom: 30px;
            }
        }
    }

    @media (max-width: 600px) {
        .garments__slide__items {
            gap: 0;

            &__single {
                min-width: 100%;

                &:nth-last-of-type(1) {
                    margin-bottom: 0;
                }
            }
        }
    }
`;

export default MyComponent;
