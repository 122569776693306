import {useEffect, useLayoutEffect, useRef} from "react";
import {gsap} from "gsap";
import {useLocation} from "react-router-dom";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useSelector} from "react-redux";

export const Parallax = () => {
    const location = useLocation();
    // gsap.registerPlugin(ScrollTrigger);
    let getPost = useSelector(state => state)

}


// how to use
// 1. Add 'parallax' class on the section. Add 'data-parallax={speed string/number: more than 2 and less than 10}' for parallax speed (if needed)
// 2. Add 'parallax' props on Img component.


export const ParallaxImg = () => {
    const location = useLocation();
    let getPost = useSelector(state => state)

    useEffect(() => {
        // ScrollTrigger.refresh()
        const getItem = gsap.utils.toArray(".parallax-img");
        getItem.forEach((item, i) => {
            let getImg = item.querySelector('.global-image img')
            let parallaxSpeed = item.getAttribute('data-parallax');

            gsap.to(getImg, {
                yPercent: parallaxSpeed ? parallaxSpeed : 15,
                // ease: "none",
                scrollTrigger: {
                    trigger: item,
                    // markers: true,
                    scrub: true
                }
            });
        });
        return () => {
            ScrollTrigger.getAll().forEach(trigger => {
                trigger.kill(); // Remove all ScrollTriggers
            });
        };
    }, [location.pathname, getPost])

}

export const ParallaxImage = () => {
    const location = useLocation();
    let getPost = useSelector(state => state)

    useEffect(() => {
        gsap.utils.toArray(".parallax-image").forEach((item, i) => {

            let getImg = item.querySelector('img')
            let parallaxSpeed = item.getAttribute('data-parallax');

            gsap.to(getImg, {
                yPercent: -25,
                // ease: "none",
                scrollTrigger: {
                    trigger: item,
                    // markers: true,
                    scrub: true
                }
            });
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => {
                trigger.kill(); // Remove all ScrollTriggers
            });
        };
    }, [location.pathname, getPost])

}

// parallax item
export const ParallaxItem = () => {
    const location = useLocation();
    let getPost = useSelector(state => state)
    useEffect(() => {
        gsap.utils.toArray(".parallax-item").forEach((item, i) => {
            let parallaxSpeed = item.getAttribute('data-speed');
            gsap.to(item, {
                y: parallaxSpeed ? parallaxSpeed : -50,
                ease: "none",
                scrollTrigger: {
                    trigger: item,
                    // markers: true,
                    scrub: true
                }
            });
        });
        return () => {
            ScrollTrigger.getAll().forEach(trigger => {
                trigger.kill(); // Remove all ScrollTriggers
            });
        };
    }, [location.pathname, getPost])
}


// parallax item horizontally
// export const ParallaxLeft = () => {
//     const location = useLocation();
//     let getPost = useSelector(state => state)
//     useEffect(() => {
//         gsap.utils.toArray(".parallax-left").forEach((item, i) => {
//             let parallaxSpeed = item.getAttribute('data-speed');
//             gsap.to(item, {
//                 x: '-20%',
//                 ease: "none",
//                 scrollTrigger: {
//                     trigger: item,
//                     // markers: true,
//                     scrub: true
//                 }
//             });
//         });
//         return () => {
//             ScrollTrigger.getAll().forEach(trigger => {
//                 trigger.kill(); // Remove all ScrollTriggers
//             });
//         };
//     }, [location.pathname, getPost])
// }