import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {Img} from "../Img";
import {hover} from "../../styles/globalStyleVars";
import Button from "../Button";
import {gsap} from "gsap";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(90)
    useEffect(() => {
        setOffset(document.querySelector('.footer .container').offsetLeft + 15)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.footer .container').offsetLeft + 15)
        })
    }, [])

    // useEffect(() => {
    // ScrollTrigger.refresh();
    // gsap.timeline({
    //     scrollTrigger: {
    //         id: `sol`,
    //         trigger: '.solutions',
    //         scrub: 2,
    //         toggleActions: 'play none none reverse',
    //     }
    // }).to('.solutions__shadow', {
    //     // ease: "linear",
    //     x: '-10%',
    //     duration: 1,
    // }).to('.solutions__right__shadow', {
    //     // ease: "linear",
    //     y: '-40%',
    //     duration: 1,
    // })

    // }, [data])


    console.log('data', data);

    return (
        <StyledComponent className={'solutions pt-200 pb-200'}>
            <Container fluid className={"p-0"}>
                <Row className={"solutions__title"}>
                    <Col sm={{span: 5, offset: 4}}>
                        <Title margin={"0 0 20px 0"} text={data?.section_data?.subtitle}/>
                        <p>{reactHtmlParser(data?.section_data?.short_desc)}</p>
                    </Col>
                </Row>

                <Row>
                    <img className={'solutions__shadow parallax-left'} alt={"shadow"}
                         src={'/images/dynamic/solution-shadow.svg'}/>
                    <Col sm={4} style={{paddingLeft: offset + 'px'}}>
                        <div className={"solutions__left"}>
                            <Img src={data?.images?.list?.find(f => f?.is_left === 'on')?.full_path}/>
                        </div>
                        <p>{reactHtmlParser(data?.section_data?.description)}</p>
                        <Button margin={"60px 0 0 0"} marginSm={"40px 0 0 0"} src={'/solutions'}/>
                    </Col>

                    <Col sm={8}>
                        <div className={"solutions__right"}>
                            <Img src={data?.images?.list?.find(f => f?.is_right === 'on')?.full_path}/>
                            <img className={"solutions__right__shadow parallax-item"}
                                 src="/images/static/shadow-cloth.png"
                                 alt="shadow"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background-image: url('/images/static/noise-sm.jpg');
  background-repeat: repeat;
  background-color: #F1EFEA;
  background-blend-mode: screen;
  overflow: hidden;
  @media (max-width: 500px) {
    background: none;
  }

  p {
    color: ${hover};
  }

  .solutions__shadow {
    position: absolute;
    top: 170px;
    max-width: 90%;
    left: -50px;
  }

  .solutions__title {
    margin-bottom: 60px;
  }

  .solutions__left {
    padding-top: calc(362 / 370 * 100%);
    position: relative;
    margin-bottom: 20px;
  }

  .solutions__right {
    padding-top: calc(654 / 868 * 100%);
    position: relative;

    &__shadow {
      position: absolute;
      bottom: -80px;
      left: -80px;
      z-index: 2;
      height: 250px;
    }
  }

  @media (max-width: 991px) {
    .solutions__title {
      .col-sm-5 {
        min-width: 100%;
        margin: 0;
        padding: 0 30px !important;
      }
    }

    .row:not(.solutions__title) {
      flex-direction: column-reverse;
      position: relative;

      .col-sm-4 {
        margin: 0;
        padding-left: 30px !important;
        padding-right: 30px;
        min-width: 100%;
      }

      .col-sm-8 {
        margin-bottom: 120px;
        min-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    .solutions__shadow {
      top: -70px;
      left: 20px;
    }

    .solutions__right__shadow {
      height: 120px;
      bottom: -60px;
    }
  }
`;

export default MyComponent;
