import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from '../redux/home'
import equipmentReducer from '../redux/equipment'
import productsReducer from '../redux/products'
import mediaReducer from '../redux/media'
import aboutReducer from '../redux/about'
import innovationReducer from '../redux/innovation'
import sustainabilityReducer from '../redux/sustainability'
import contactReducer from '../redux/contact'
import solutionReducer from '../redux/solution'
import footerReducer from '../redux/footer'
import searchReducer from '../redux/search'
import menuReducer from '../redux/menu'

const store = configureStore({
    reducer: {
        postReducer,
        home: homeReducer,
        equipment: equipmentReducer,
        products: productsReducer,
        media: mediaReducer,
        about: aboutReducer,
        sustainability: sustainabilityReducer,
        contact: contactReducer,
        solution: solutionReducer,
        footer: footerReducer,
        search: searchReducer,
        menu: menuReducer,
        innovationReducer,
        globalReducer
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

