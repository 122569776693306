import React from 'react';
import styled from 'styled-components';
import {hover, text, title, title_ms60} from "../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const Title = ({
                   text,
                   fontSize,
                   fontWeight,
                   color,
                   letterSpacing,
                   lineHeight,
                   textTransform,
                   margin,
                   marginSm,
                   padding,
                   borderColor,
                   varient,
                   center,
                   classname,
                   noAnim
               }) => {


    return (

        <StyledTitle className={`title ${classname}`}
                     fontSize={fontSize}
                     fontWeight={fontWeight}
                     color={color}
                     lineHeight={lineHeight}
                     LetterSpacing={letterSpacing}
                     textTransform={textTransform}
                     margin={margin}
                     marginSm={marginSm}
                     padding={padding}
                     varient={varient}
                     center={center}
                     borderColor={borderColor}>
            {text && <h2 className={noAnim ? '' : 'split-up'}>{ReactHtmlParser(text)} </h2>}

        </StyledTitle>

    )
};


const StyledTitle = styled.div`
    margin: ${props => props.margin || '0px'};
    position: relative;
    width: fit-content;
    text-align: ${props => props?.center ? 'center' : ''};
    padding: ${p => p.padding};

    h2 {
        font-family: ${title};
        font-size: ${props => props.fontSize || 48}px;
        line-height: ${props => props.fontSize || 48}px;
        text-transform: uppercase;
        font-weight: ${props => props.fontWeight};
        color: ${props => props.color || text};
    }


    @media (max-width: 767px) {
        margin: ${p => p.marginSm}px;
        padding: 0;
        h2 {
            font-size: 36px !important;
            line-height: 36px !important;
        }
    }
`;


export default Title;














