export const ApiParam = {
    PAGE_ID: 'page_id',
    PAGE_SLUG: 'page_slug',
    VALUE: 'value',
    GET_SECTION: 'get_section',
    IMAGE: 'image',
    POST: 'post',
    FILE: 'file',
    GALLERY: 'gallery',
    TYPE: 'type',
    CATEGORY: 'category',
    LOCATION: 'location',
    CATEGORY_ID: 'category_id',
    type: 'type',
    value: 'value',
    SPECIFICATION: 'specification',
};
