import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {text, title, Transition, White} from "../../styles/globalStyleVars";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import {Img} from "../Img";
import {useSelector} from "react-redux";

const MyComponent = ({data}) => {

    const footerData = useSelector(store => store.footer)
    const [index, setIndex] = useState(1)

    const [innerWidth, setInnerWidth] = useState(0)
    const [imageIndex, setImagerIndex] = useState(1)
    const handleIndex = (e) => {
        setImagerIndex(e + 1)
    }

    useEffect(() => {
        setInnerWidth(window.innerWidth)
        window.addEventListener('resize', () => {
            setInnerWidth(window.innerWidth)
        })
    }, [])


    // slider ---
    const swiperRef = useRef(null);
    const swiperRefTwo = useRef(null);

    const handleProgress = (swiper, event) => {
        var interleaveOffset = 0.8;
        for (let i = 0; i < swiper.slides.length; i++) {
            var slideProgress = swiper.slides[i].progress;
            var innerOffset = swiper.width * interleaveOffset;
            var innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".global-image").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
        }
    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };

    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".global-image")
                .style.transition = `${speed}ms`;
        }
    };

    // handle pagination
    var pagination_title = ['Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities', 'Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities']
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="' + className + '"><span>' + pagination_title[index] + "</span></div>";
        },
    };
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const handleSlideChange = (event) => {
        const newActiveSlideIndex = event.realIndex;
        setActiveSlideIndex(newActiveSlideIndex);
        if (swiperRefTwo.current) {
            swiperRefTwo.current.swiper.slideTo(newActiveSlideIndex);
        }

        handleIndex(event.activeIndex)
        setIndex(event.activeIndex + 1)
    };

    const handleButtonClickPagination = (index) => {
        if (swiperRef.current) {
            setActiveSlideIndex(index);
            swiperRef.current.swiper.slideToLoop(index);
        }
    };


    // offset
    const [offset, setOffset] = useState(0)
    useEffect(() => {
        setOffset(document.querySelector('.footer .container').offsetLeft + 15)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.footer .container').offsetLeft + 15)
        })
    }, [])
    return (
        <StyledComponent className={'banner-slider'} style={{height: "100svh"}}>

            <div className="banner-slider__init">
                {data?.length > 0 &&
                    <Swiper key={'1'} ref={swiperRef}
                            loop={false}
                            infinite={false}
                            spaceBetween={0}
                            slidesPerView={1}
                            allowTouchMove={true}
                            speed={1200}
                            onSlideChange={handleSlideChange}
                            onProgress={handleProgress}
                            touchStart={handleTouchStart}
                            onSetTransition={handleSetTransition}
                            watchSlidesProgress={true}
                            centeredSlides={false}
                            pagination={{
                                clickable: true
                            }}
                            navigation={{
                                prevEl: '.slide-prev',
                                nextEl: '.slide-next',
                            }}

                            modules={[Autoplay, Navigation, Pagination]}
                            autoplay={{
                                delay: 8000,
                            }}
                            className="mySwiper main-swiper"
                    >

                        {data?.map(i => (
                            <SwiperSlide key={i?.data?.slug}>
                                <Img
                                    srcSm={i?.images?.find(f => f?.is_mobile === 'on')?.full_path || i?.images?.find(f => f?.is_desktop === 'on')?.full_path}
                                    src={i?.images?.find(f => f?.is_desktop === 'on')?.full_path}/>

                                <div className="banner-slider__item__content">
                                    <h3>{i?.data?.subtitle}</h3>
                                    <h3>{i?.data?.short_desc}</h3>
                                    <h4>{i?.data?.description}</h4>
                                </div>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                }
                <div className="slide-prev" style={{left: offset + 'px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                        <g id="Nav_-_L" data-name="Nav - L" transform="translate(-98 -345)">
                            <g id="Group_5548" data-name="Group 5548"
                               transform="translate(1386.5 2212.5) rotate(180)">
                                <line id="Line_7" data-name="Line 7" x2="5" y2="5"
                                      transform="translate(1253.5 1822.5)"
                                      fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_8" data-name="Line 8" y1="5" x2="5"
                                      transform="translate(1253.5 1827.5)"
                                      fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_9" data-name="Line 9" x2="20" transform="translate(1238.5 1827.5)"
                                      fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <g id="Ellipse_389" data-name="Ellipse 389" transform="translate(178 425) rotate(180)"
                               fill="none" stroke="#fff" stroke-width="1" opacity="0.2">
                                <circle cx="40" cy="40" r="40" stroke="none"/>
                                <circle cx="40" cy="40" r="39.5" fill="none"/>
                            </g>
                            <g id="Ellipse_596" data-name="Ellipse 596" transform="translate(178 345) rotate(90)"
                               fill="none" stroke="#fff" stroke-width="1" stroke-dasharray="1 260">
                                <circle cx="40" cy="40" r="40" stroke="none"/>
                                <circle cx="40" cy="40" r="39.5" fill="none"/>
                            </g>
                        </g>
                    </svg>

                </div>
                <div className="slide-next" style={{right: offset + 'px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                        <g id="Nav_-_R" data-name="Nav - R" transform="translate(-1188 -345)">
                            <g id="Group_5548" data-name="Group 5548" transform="translate(-20.5 -1442.5)">
                                <line id="Line_7" data-name="Line 7" x2="5" y2="5"
                                      transform="translate(1253.5 1822.5)"
                                      fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_8" data-name="Line 8" y1="5" x2="5"
                                      transform="translate(1253.5 1827.5)"
                                      fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_9" data-name="Line 9" x2="20" transform="translate(1238.5 1827.5)"
                                      fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <g id="Ellipse_389" data-name="Ellipse 389" transform="translate(1188 345)" fill="none"
                               stroke="#fff" stroke-width="1" opacity="0.2">
                                <circle cx="40" cy="40" r="40" stroke="none"/>
                                <circle cx="40" cy="40" r="39.5" fill="none"/>
                            </g>
                            <g id="Ellipse_596" data-name="Ellipse 596" transform="translate(1188 425) rotate(-90)"
                               fill="none" stroke="#fff" stroke-width="1" stroke-dasharray="1 260">
                                <circle cx="40" cy="40" r="40" stroke="none"/>
                                <circle cx="40" cy="40" r="39.5" fill="none"/>
                            </g>
                        </g>
                    </svg>

                </div>
            </div>

            <Container className="banner-slider__bottom">
                <p>{index} / {data?.length}</p>
                <ul>
                    <li><a href={footerData?.posts?.data?.facebook} target={"_blank"}><img src="/images/static/fb-2.svg"
                                                                                           alt=""/></a></li>
                    <li><a href={footerData?.posts?.data?.twitter} target={"_blank"}><img src="/images/static/x-2.svg"
                                                                                          alt=""/></a>
                    </li>
                    <li><a href={footerData?.posts?.data?.instagram} target={"_blank"}><img
                        src="/images/static/insta-2.svg"
                        alt=""/></a></li>
                    <li><a href={footerData?.posts?.data?.youtube} target={"_blank"}><img
                        src="/images/static/youtube-2.svg"
                        alt=""/></a></li>
                    <li><a href={footerData?.posts?.data?.linkedin} target={"_blank"}><img src="/images/static/in-2.svg"
                                                                                           alt=""/></a></li>
                </ul>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    //height: 100vh;
    position: relative;
    background-color: #000;

    .swiper {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 5;
        overflow: hidden;
    }

    .swiper-slide {
        overflow: hidden;

    }

    .banner-slider__init {

        .banner-slider__item {
            position: relative;
            width: 100%;

            &__content {
                text-align: center;
                position: absolute;
                z-index: 8;
                width: fit-content;
                height: fit-content;
                margin: auto;
                inset: 0;
                max-width: calc(100% - 30px);

                h3 {
                    font-size: 80px;
                    line-height: 60px;
                    font-family: ${title};
                    margin-bottom: 15px;
                    color: #F3F3F3;
                    -webkit-background-clip: text !important;
                    opacity: 0;
                    transition: all .8s ease;
                    transition-delay: 0s;
                    transform: translateY(15px);

                    &:nth-of-type(2) {
                        margin-top: -20px;
                    }
                }

                h4 {
                    font-size: 80px;
                    line-height: 60px;
                    font-family: ${title};
                    color: #F3F3F3;
                    -webkit-background-clip: text !important;
                    opacity: 0;
                    transform: translateY(15px);
                    transition-delay: 0s;
                    transition: all .8s ease;
                    font-style: italic;
                    margin-top: -18px;
                }
            }
        }
    }


    .banner-slider__content {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;

    }

    .slide-prev {
        position: absolute;
        z-index: 5;
        left: 40px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
        cursor: pointer;

        svg g {
            transition: all .8s ${Transition};
        }

        #Ellipse_596 {
            opacity: 1;
            stroke-dasharray: 0, 520;
        }

        &:hover {
            svg #Ellipse_596 {
                stroke-dasharray: 250;
                stroke-width: 1;
            }
        }
    }

    .slide-next {
        position: absolute;
        z-index: 5;
        right: 40px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
        cursor: pointer;

        svg g {
            transition: all .8s ${Transition};
        }

        #Ellipse_596 {
            opacity: 1;
            stroke-dasharray: 0, 520;
        }

        &:hover {
            svg #Ellipse_596 {
                stroke-dasharray: 250;
                stroke-width: 1;
            }
        }
    }

    .swiper-slide-active {
        .banner-slider__item__content {
            h4 {
                opacity: .8;
                transition-delay: 1.3s;
                transform: none;

                &:nth-last-of-type(1) {
                    transition-delay: 1.5s;
                }
            }

            h3 {
                opacity: .9;
                transform: none;
                transition-delay: 1.1s;

                &:nth-of-type(2) {
                    transition-delay: 1.3s;
                }
            }
        }
    }

    .banner-slider__bottom {
        position: absolute;
        bottom: 35px;
        z-index: 5;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: space-between;

        p {
            font-size: 16px;
            line-height: 20px;
            color: ${White};
        }

        ul {
            display: flex;

            li {
                a {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: filter .3s;

                    &:hover {
                        filter: invert(60%) sepia(10%) saturate(947%) hue-rotate(335deg) brightness(90%) contrast(86%);
                    }
                }

                margin-right: 10px;
            }
        }
    }

    .swiper-pagination {
        bottom: 38px;
    }

    .swiper-pagination-bullet {
        width: 100px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 0;
        margin: 0 !important;
        height: 1px;
        opacity: 1;

        &.swiper-pagination-bullet-active {
            position: relative;


            &:after {
                position: absolute;
                width: 100%;
                left: 0;
                content: '';
                height: 3px;
                bottom: -1px;
                background-color: #fff;
                box-shadow: 0px 0px 19px #FFF;
            }
        }
    }

    @media (max-width: 991px) {
        .banner-slider__item__content {
            h3, h4 {
                font-size: 60px;
                line-height: 50px;
            }
        }

        .slide-prev, .slide-next {
            display: none;
        }

        .banner-slider__bottom {
            bottom: 60px;
        }

        .swiper-pagination {
            bottom: 20px;
        }

    }

    @media (max-width: 768px) {
        .banner-slider__init .banner-slider__item__content {
            h1 {
                font-size: 60px;
                line-height: 60px;
            }
        }
    }

    @media (max-width: 767px) {
        .banner-slider__menu ul {
            width: calc(100% - 30px);

            li {
                &:not(:nth-last-of-type(1)) {
                    margin-right: 5px;
                }

                .banner-slider__menu__inner {
                    padding-top: calc(102 / 111 * 100%);
                }

            }

            .banner-slider__menu__inner {
                img {
                    height: 35px !important;
                }

                a {
                    font-size: 14px !important;
                }
            }
        }

    }

`;

export default MyComponent;
