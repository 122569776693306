import React from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {ParallaxProvider} from "react-scroll-parallax";
import Button from "../Button";
import {title} from "../../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const InnerBanner = ({logo, full, project, sus, id, data}) => {
    console.log(data)
    return (
        <StyledInnerBannerTen id={`${id ? id : 'InnerBanner'}`} sus full={full}
                              className={`InnerBanner ${project ? 'project_banner' : ''} ${sus ? 'sustainbanner' : ''}`}>
            <img src={'/images/dynamic/sustainability-banner.jpg'} className={'fixed-image'} alt=""/>
            <ParallaxProvider>
                <Container id={'hero-banner'} className={`InnerBanner__parallax_v10 ${sus ? 'sustain' : ''}`}>
                    <Row>
                        <Col className={'divider'} lg={9} md={12}>
                            <div className={`flex_box ${sus ? 'sustain' : ''}`}>
                                <div className="title">
                                    <div>
                                        {data?.section_data?.subtitle &&
                                            <h1 className={'parallax-item'}>{ReactHtmlParser(data?.section_data?.subtitle)}</h1>}
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <Row className={'second_container'}>
                        <Col className={'column_right'} lg={{span: 4, offset: 8}} md={12}>
                            {data?.section_data?.short_desc &&
                                <p className={'parallax-item'}>{ReactHtmlParser(data?.section_data?.short_desc)}</p>}
                            <Button borderColor={'#F1EFEA'} text={'Sustainability Report'} color={'#F1EFEA'}
                                    borderColor={'#F1EFEA'} hoverBackground={'#F1EFEA'} background={'#F1EFEA'}/>
                        </Col>
                    </Row>
                </Container>
            </ParallaxProvider>
        </StyledInnerBannerTen>
    );
};

const StyledInnerBannerTen = styled.section`
  position: relative;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(28, 23, 24, 0%);
    content: '';
  }

  .fixed-image {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;

    img {
      object-fit: cover;
    }
  }

  .InnerBanner__parallax_v10 {
    position: relative;
    z-index: 1;
    padding-top: 375px;
    padding-bottom: 500px;

    @media (max-width: 767px) {
      padding-top: 220px;
      bottom: 40px;
      padding-bottom: 0px;
    }

    h1 {
      z-index: 2;
      font-size: 80px;
      line-height: 80px;
      font-family: ${title};
      margin: 0;
      color: #F1EFEA;

      span {
        font-size: 80px;
        line-height: 80px;
        font-family: ${title};
        margin: 0;
        color: #F1EFEA;
        font-style: italic;

      }

      @media (max-width: 767px) {
        font-size: 48px !important;
        line-height: 52px !important;
        span {
          font-size: 48px !important;
          line-height: 52px !important;
        }
      }
    }

    .flex_box {
      display: flex;
      justify-content: space-between;

      .text {
        position: relative;
        z-index: 2;

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: rgba(240, 237, 227, 0.8);
          text-align: right;
        }
      }
    }


    .second_container {
      margin-top: 60px;
    }


    .column_right {
      position: relative;

      &:before {
        position: absolute;
        content: url('/images/static/leaf-xl.svg');
        bottom: 0px;
        background-repeat: no-repeat;
        z-index: -1;
        transform: translateX(-230px);
        width: 100%;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #F1EFEA;
        padding-bottom: 60px;
        z-index: 4;
      }
    }


    &.sustain {
      padding-top: 160vh;;
    }

  }

  &.project_banner {
    .title {
      position: relative;
      z-index: 2;

      h1 {
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 28px !important;
      }
    }
  }


  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    height: 1500px;
    .column_right::before {
      display: none;
    }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    height: 1200px;
    .InnerBanner__parallax_v10 {
      .flex_box {
        flex-direction: column;
        text-align: left;

        .text {
          margin-top: 80px;

          p {
            text-align: left;
          }
        }
      }

      .second_container {
        margin-top: 40px;
      }

      .column_left {

        .logo_ipsum {
          position: relative;
          z-index: 1;

          p {
            margin-top: 0;
          }

          a {
            display: none;
          }

          img {
            height: 45px;
            display: none;
          }
        }
      }


      &.sustain {
        padding-top: 175vh;
      }


      .container {
        .divider {
          padding-bottom: 40px;
        }

        bottom: 40px;

        h1 {
          font-size: 40px !important;
          line-height: 40px !important;

          br {
            display: none;
          }
        }

      }
    }

    .column_right::before {
      display: none;
    }

    .gallery-svg__section p {
      padding: 60px 0px 40px 0;
    }
  }
`;

export default InnerBanner;
