import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useParams} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Detail from "../../components/media/Detail";
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchMediaCenterDetail} from "../../api/redux/media";
import RelatedNews from "../../components/media/RelatedNews";
import {SplitUp} from "../../components/animations/TextAnimation";
import {ParallaxImage, ParallaxImg, ParallaxItem} from "../../components/animations/Parallax";

const MyComponent = ({data}) => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch()
    const getData = useSelector((state) => state.media);
    let {slug} = useParams();

    SplitUp()
    ParallaxImg()
    ParallaxImage()
    ParallaxItem()

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                let api_url = apiEndPoints.BLOG_DATA;

                let api_params = {
                    [ApiParam.TYPE]: "slug",
                    [ApiParam.VALUE]: slug,
                    [ApiParam.IMAGE]: "yes",
                    [ApiParam.POST]: "yes",
                };

                await dispatch(fetchMediaCenterDetail([api_url, api_params]));

                setIsLoading(false);
            } catch (err) {
                // Handle errors if needed
                setIsLoading(false);
            }
        }
        fetchData()
    }, [dispatch]);

    const detail = getData?.detail?.data;

    console.log('detail', getData?.detail)

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Media | Triple Apparel Limited</title>
                <meta
                    content="Triple Apparels Ltd is the latest addition to the Partex Star family, a household name in Bangladesh. We are well poised to position ourselves as one of the largest global apparel manufacturers with world-class capabilities and standards."
                    name="description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner img={"/images/dynamic/detail-banner.jpg"}/>
                <Detail data={detail}/>
                <RelatedNews/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
