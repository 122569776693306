import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Col, Container, Row} from "react-bootstrap";
import {title, White} from "../styles/globalStyleVars";


const InnerBanner = ({img, text, title, imgSm}) => {
    return (
        <StyledInnerBanner className='InnerBanner'>
            <Img srcSm={imgSm ? imgSm : img} banner={true} src={img}/>
            <Container>
                <Row>
                    <Col sm={9}>
                        {title && <h1 className={'parallax-item'}>{title}</h1>}
                    </Col>
                    <Col sm={3} className={"d-flex align-items-end"}>
                        {text && <p className={'parallax-item'}>{text}</p>}
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(420 / 1366 * 100%);
  position: relative;
  background-color: #DDD;

  &:after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.25);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    //height: 100%;
    //top: 0;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 75px;
    z-index: 2;
  }

  h1 {
    color: ${White};
    text-transform: uppercase;
    font-family: ${title};
    font-size: 48px;
    line-height: 58px;
  }

  p {
    color: ${White};
  }

  @media (max-width: 991px) {
    padding: 0;
    height: 50vh;
    .container {
      bottom: 40px;
    }

    .col-sm-9, .col-sm-3 {
      min-width: 100%;
    }

    h1 {
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 10px;
    }
  }
`;

export default InnerBanner;
