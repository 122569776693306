import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {textMid, White} from "../../styles/globalStyleVars";
import {Img} from "../Img";

const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(90)
    useEffect(() => {
        setOffset(document.querySelector('.footer .container').offsetLeft + 15)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.footer .container').offsetLeft + 15)
        })
    }, [])

    return (
        <StyledComponent className={"capability"}>
            <div className="capability__wrap">
                <Col sm={5} className={'capability__img p-0 parallax-image'}>
                    <img src={data?.images?.list?.filter((f) => f?.is_left === "on")?.[0]?.full_path} alt={'side'}/>
                </Col>
                <Col sm={7} className={"capability__wrap__desc"} style={{paddingRight: offset + 'px'}}>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <Title color={White} margin={"0 0 20px 0"} text={data?.section_data?.subtitle}
                           fontSize={"32"} lineHeight={"36"}/>
                    {/*<p>Where Incomparable Meets Innovative wellbeing</p>*/}
                    <ul>
                        {data?.posts?.list && data?.posts?.list?.length > 0 &&
                            data?.posts?.list?.map((e, i) => {
                                return (
                                    <li key={i}>
                                        <img src={e?.images?.[0]?.full_path} alt="icon"/>
                                        <div className="wrap">
                                            <h5>{e?.data?.title}</h5>
                                            <p>{e?.data?.description}</p>
                                        </div>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </Col>


            </div>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F1EFEA;

  .capability__wrap {
    display: flex;
    flex-wrap: wrap;

    position: relative;

    &:after {
      background-image: url('/images/static/noise-sm.jpg');
      background-repeat: repeat;
      background-color: #F1EFEA;
      background-blend-mode: screen;
      height: 40%;
      top: 0;
      left: 0;
      width: 100%;
      content: '';
      position: absolute;
      @media (max-width: 500px) {
        background: none;
      }
    }

    &__desc {
      background-color: #000;
      padding-top: 125px;
      padding-bottom: 125px;
      padding-left: 100px;
      position: relative;
      background-image: url('/images/dynamic/lather.jpg');
      background-repeat: repeat;
      z-index: 5;
      overflow: hidden;
      height: fit-content;

      span {
        position: absolute;
        background-color: rgba(241, 239, 234, 0.20);

        &:nth-of-type(1) {
          left: 33.33%;
          height: 100%;
          width: 1px;
          top: 0;
        }

        &:nth-of-type(2) {
          right: 33.33%;
          height: 100%;
          width: 1px;
          top: 0;
        }

        &:nth-of-type(3) {
          top: 25%;
          width: 100%;
          height: 1px;
          left: 0;
        }

        &:nth-of-type(4) {
          top: 50%;
          width: 100%;
          height: 1px;
          left: 0;
        }

        &:nth-of-type(5) {
          bottom: 25%;
          width: 100%;
          height: 1px;
          left: 0;
        }
      }

      p {
        font-size: 16px;
        line-height: 20px;
        color: #b7b4b0;
      }

      ul {
        margin-top: 80px;
        padding-left: 80px;


        li {
          display: flex;
          gap: 0 60px;
          align-items: flex-start;

          &:not(:nth-last-of-type(1)) {
            margin-bottom: 80px;
          }

          .wrap {
            max-width: calc(100% - 60px);

            h5 {
              font-size: 22px;
              line-height: 24px;
              font-weight: 500;
              font-family: ${textMid};
              color: ${White};
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .capability__img {
      position: relative;
      z-index: 5;

      img {
        width: 100%;
        object-fit: contain;
        margin-top: 200px;
      }
    }
  }

  @media (max-width: 991px) {
    .capability__wrap {
      &__desc {
        min-width: 100%;
        padding-left: 50px;

        ul {
          padding-left: 0;

          li {
            flex-wrap: wrap;

            .wrap {
              min-width: 100%;
              margin-top: 25px;
            }

            &:not(:nth-last-of-type(1)) {
              margin-bottom: 40px;
            }
          }
        }
      }

      .capability__img {
        img {
          margin-top: 0;
        }
      }

    }
  }

  @media (max-width: 767px) {
    .capability__wrap__desc {
      padding-left: 15px;
    }
  }
`;

export default MyComponent;
