import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import SingleMedia from "../SingleMedia";
import {text, textMid, White} from "../../styles/globalStyleVars";

const MyComponent = ({mediaData, categories, handleCat, activeCat}) => {
    return (
        <StyledComponent className={'media-list pt-100 pb-100'}>
            <Container>
                <div className="media-list__filter">
                    <ul>
                        <li onClick={() => handleCat(0)} className={activeCat === 0 ? 'active' : null}>All</li>
                        {
                            categories?.map((item) => {
                                return (
                                    <li className={activeCat === item?.id ? 'active' : null} key={item?.id}
                                        onClick={() => handleCat(item?.id, item?.slug)}>{item?.title}</li>
                                )
                            })
                        }
                    </ul>
                </div>

                <Row className={'list-wrap'}>
                    {
                        mediaData &&
                        mediaData?.length > 0 &&
                        mediaData?.map((item) => {
                            const dateObject = new Date(item?.data?.date);
                            const getMonth = dateObject.toLocaleString('default', {month: 'long'});
                            const getYear = dateObject.getFullYear();
                            const getDate = dateObject.getDate();

                            // Check if item.images exists and is an object with a list property
                            const imageList = item?.images?.list;

                            // Check if imageList is an array and not empty
                            const thumbnailImage = Array.isArray(imageList) && imageList.length > 0
                                ? imageList.find((f) => f?.thumb === "on")
                                : null;

                            const imgPath = thumbnailImage?.full_path || '';

                            return (
                                <Col sm={4} key={item?.id}>
                                    <SingleMedia
                                        title={item?.data?.title}
                                        date={getDate}
                                        year={getYear}
                                        img={imgPath}
                                        month={getMonth}
                                        link={`/media/${item?.data?.slug}`}
                                        type={item?.data?.category_title}
                                    />
                                </Col>
                            );
                        })
                    }


                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .col-sm-4 {
    margin-bottom: 30px;
  }

  .list-wrap {
    min-height: 50vh;
  }

  .media-list__filter {
    margin-bottom: 60px;

    ul {
      display: flex;
      border-bottom: 2px solid #F1EFEA;
      gap: 0 30px;

      li {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        font-family: ${textMid};
        flex: 1;
        color: #B7B4B0;
        padding-bottom: 20px;
        position: relative;
        cursor: pointer;

        &.active {
          color: ${text};

          &:after {
            position: absolute;
            bottom: -2px;
            left: 0;
            background-color: ${text};
            width: 100%;
            height: 2px;
            content: '';
          }
        }

      }

    }
  }

  .col-sm-4 {
    &:nth-of-type(1) {
      .blog-single__inner__content:after {
        height: 0;
      }

      .noise {
        height: 0;
      }

      .blog-single__inner__content__top {
        h4 {
          //opacity: 0;
          //transform: translateY(-20px);
          color: ${White};;
        }

        p {
          transform: none;
          //opacity: 1;
        }
      }

      .blog-single__inner__content__bottom {
        border-color: ${White};

        h2 {
          color: ${White};
        }

        p {
          color: ${White};

          span {
            color: ${White};
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .col-sm-4 {
      min-width: 50%;
    }
  }

  @media (max-width: 600px) {
    .media-list__filter {
      ul {
        gap: 0;

        li.active:after {
          width: 70%;
        }
      }
    }
  }

`;

export default MyComponent;
