import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import 'react-modal-video/css/modal-video.min.css'
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Mousewheel, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import ReactHtmlParser from "react-html-parser";
import {textMid} from "../../styles/globalStyleVars";

const Navbar = ({data}) => {
    const section1Ref = useRef(null);
    const [offset, setOffset] = useState(100)

    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])


    // width control
    const sourceDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth - 15;
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth}px`);
        };

        handleResize();

        window.addEventListener('reload', handleResize);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('reload', handleResize);
        };
    }, []);

    // halfcut

    const sourceDivReff = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullHeight = sourceDivReff.current?.offsetHeight;
            const adjustedHeight = (fullHeight / 2) + 15;
            const final = (adjustedHeight / 2);
            document.documentElement.style.setProperty('--target-height', `${final}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <StyledMediaList>

                <div className={'media-list'} ref={section1Ref}>
                    <StyledComponent className='csr' offset={offset}>
                        <div className="top">
                            <Container>
                                <Row className={'gallery-svg__section'}>

                                    <Col lg={5} ref={sourceDivRef}>
                                        <p>{ReactHtmlParser(data?.section_data?.subtitle)}</p>
                                    </Col>
                                    <Col lg={{offset: 3}} ref={sourceDivRef}>

                                        <div className="gallery-svg__section__arrow">
                                            <ul>
                                                <li id={"csr-prev1"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="41.207"
                                                         height="11.414" viewBox="0 0 41.207 11.414">
                                                        <line id="Line_7" data-name="Line 7" x2="5" y2="5"
                                                              transform="translate(35.5 0.707)" fill="none"
                                                              stroke="#f1efea" stroke-linecap="round" stroke-width="1"/>
                                                        <line id="Line_8" data-name="Line 8" y1="5" x2="5"
                                                              transform="translate(35.5 5.707)" fill="none"
                                                              stroke="#f1efea" stroke-linecap="round" stroke-width="1"/>
                                                        {/*<line id="Line_9" data-name="Line 9" x2="40" transform="translate(0.5 5.707)" fill="none" stroke="#f1efea" stroke-linecap="round" stroke-width="1"/>*/}
                                                    </svg>

                                                </li>
                                                <li id={"csr-next1"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="42.207"
                                                         height="11.414" viewBox="0 0 42.207 11.414">
                                                        <g id="Animation_-_Arrow_-_Nav_R"
                                                           data-name="Animation - Arrow - Nav R"
                                                           transform="translate(0.5 0.707)">
                                                            <line id="Line_7" data-name="Line 7" x2="5" y2="5"
                                                                  transform="translate(36)" fill="none" stroke="#f1efea"
                                                                  stroke-linecap="round" stroke-width="1"/>
                                                            <line id="Line_8" data-name="Line 8" y1="5" x2="5"
                                                                  transform="translate(36 5)" fill="none"
                                                                  stroke="#f1efea" stroke-linecap="round"
                                                                  stroke-width="1"/>
                                                            {/*<line id="Line_10" data-name="Line 9" x2="40" transform="translate(0 5)" fill="none" stroke="#f1efea" stroke-linecap="round" stroke-width="1"/>*/}
                                                        </g>
                                                    </svg>

                                                </li>
                                            </ul>
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <div className="clearfix"></div>
                        <LightgalleryProvider>
                            <Container fluid className={'p-0'}>
                                <Row>
                                    <Col className={'slider_overlap'}>
                                        <Swiper
                                            loop={false}
                                            spaceBetween={15}
                                            speed='1000'
                                            slidesPerView={4}
                                            keyboardControl={true}
                                            initialSlide={1}
                                            modules={[Pagination, Navigation, Mousewheel]}
                                            navigation={{
                                                prevEl: '#csr-prev1',
                                                nextEl: '#csr-next1',
                                            }}
                                            breakpoints={{
                                                320: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 15,

                                                },
                                                768: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 15,

                                                },
                                                1024: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 15,
                                                },
                                            }}
                                            className="mySwiper"
                                        >
                                            {data?.images?.list?.map((sliderItem, sliderIndex) => (
                                                <SwiperSlide key={sliderIndex} ref={sourceDivReff}>
                                                    <div className="single single_testimonial">
                                                        <LightgalleryItem src={sliderItem?.full_path}>
                                                            <div className="wrapper_box">
                                                                <div className="image_wrapper">
                                                                    <Img
                                                                        src={sliderItem?.full_path}/>
                                                                </div>
                                                            </div>
                                                        </LightgalleryItem>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </Col>
                                </Row>
                            </Container>
                        </LightgalleryProvider>

                    </StyledComponent>
                </div>

            </StyledMediaList>

        </>
    )
};

const StyledComponent = styled.section`
  .top {
    padding-bottom: 60px;
    position: relative;
    z-index: 2;
  }

  .swiper-button-disabled {
    opacity: 1 !important;
  }

  .gallery-svg__section {
    padding-top: 100px;

    p {
      font-size: 24px;
      font-weight: 500;
      font-family: ${textMid};
      line-height: 28px;
      color: #F1EFEA;
    }

    &__arrow {
      ul {
        display: flex;
        gap: 20px;

        li {
          cursor: pointer;
          overflow: hidden;

          &:first-child {
            transform: rotate(180deg);
            display: flex;
            align-items: center;
            transition: 0.3s;
            position: relative;

            &:before {
              position: absolute;
              content: '';
              background: #f1efea;
              height: 1px;
              width: 40px;
              left: 0.5px;
              transition: left 1s ease-in-out;
            }

            &:hover {
              &:before {
                left: 10px;
              }
            }

            //&:hover {
            //  line#Line_9 {
            //    stroke-dasharray: -20px;
            //  }
            //}
          }

          &:nth-last-of-type(1) {
            position: relative;
            display: flex;
            align-items: center;
            overflow: hidden;

            &:before {
              position: absolute;
              content: '';
              background: #f1efea;
              height: 1px;
              width: 40px;
              left: 0.5px;
              transition: left 1s ease-in-out;
            }

            &:hover {
              &:before {
                left: 10px;
              }
            }
          }

          //&:hover {
          //  line#Line_9 {
          //    stroke-dashoffset: 40px; /* Adjusted to move from right to left */
          //  }
          //}
        }
      }
    }
  }


  line#Line_10 {
    stroke-dasharray: 39px;
    transition: 0.3s;
  }

  .swiper {
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'};
    height: var(--target-width) !important;
    @media (min-width: 768px) and (max-width: 991px) {
      height: 100% !important;
    }
  }

  .swiper-button-next, .swiper-button-prev {
    display: none;
  }


  .swiper-slide {
    transition: 0.7s all ease;
    padding-right: 15px;
    @media (max-width: 991px) {
      padding-right: 0;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-left: 15px;
    }
  }

  .swiper-pagination {
    width: auto;
    bottom: 120px;
    left: 155px;
    @media (min-width: 1550px) {
      //bottom: 60px;
    }
    @media (max-width: 991px) {
      left: 5px;
      bottom: 85px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 3px;
      display: inline-block;
      background: #C49F8A;
      border-radius: 4px;
      opacity: 1;
      margin: 0 10px;

      &.swiper-pagination-bullet-active {
        width: 70px !important;
        background: #565440;
      }
    }
  }


  .swiper-slide-active {
    width: var(--target-width) !important;
    @media (max-width: 991px) {
      width: 100% !important;
    }

  }

  .single_testimonial {

    .image_wrapper {
      padding-top: calc(570 / 570 * 100%) !important;

      img {
        transform: unset !important;
      }
    }
  }
}

.single_testimonial {
  //padding-right: 15px;
  box-sizing: border-box;
  @media (max-width: 991px) {
    padding-right: 15px;
    padding-left: 15px;
  }
  @media (max-width: 991px) {
    padding-right: 15px;
  }

  .wrapper_box {
    display: flex;
    position: relative;

    @media (max-width: 991px) {
      flex-direction: column;
      .image_wrapper {
        margin-bottom: 30px;
      }
    }


    .image_wrapper {
      padding-top: calc(312 / 370 * 100%);
      transition: 0.7s all ease;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      width: 100%;

      .play {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;

        svg {
          #Ellipse_396 {
            transition: 0.7s all ease;
          }
        }
      }

      .global-image {
        transition: 0.7s all ease;
        transform: scale(1.01);
      }

      &:hover {
        .global-image {
          transform: scale(1.06);
        }

        svg {
          #Ellipse_396 {
            r: 25;
          }
        }
      }
    }


  }
}

@media (max-width: 991px) {
  .swiper {
    padding-left: unset;

    .container-fluid {
      padding: 0 15px 0 0 !important;
    }

    .title {
      margin-bottom: 30px !important;
    }
  }

`;

const StyledMediaList = styled.section`
  margin-top: -440px;
  z-index: 2;

  .media-list {
    .csr {
      .container-fluid {
        position: relative;
        bottom: -1px;
        @media (max-width: 991px) {
          padding-bottom: 0px !important;
        }

      }
    }
  }

  .csr {
    @media (max-width: 991px) {
      padding-top: -330px;
    }
  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    .gallery-svg__section {
      padding-top: 0px;
    }

    .gallery-svg__section p {
      padding-bottom: 40px;
    }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    .gallery-svg__section {
      padding-top: 0px;
    }

    .gallery-svg__section p {
      padding-bottom: 40px;
    }
  }


`;
export default Navbar;
