import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import {text, textMid} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import Select, {components} from "react-select";
import SingleProduct from "../SingleProduct";

const MyComponent = ({products, categories, handleCat, activeCat, loading}) => {
    // dropdown style
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? '#2B3382' : '#FFF',
            margin: 0,
            cursor: 'pointer',
            textTransform: 'capitalize',
            transition: '.3s ease',
            ":hover": {
                backgroundColor: "#2B3382",
                color: '#FFF'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,

        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer'
        }),

    };

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.732" height="7.003"
                             viewBox="0 0 11.732 7.003">
                            <g id="Group_24047" data-name="Group 24047" transform="translate(18853.867 -10435.998)">
                                <g id="Group_24046" data-name="Group 24046"
                                   transform="translate(-18852.863 10442.258) rotate(-93)">
                                    <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none"
                                          stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_12381" data-name="Line 12381" y1="5" x2="5"
                                          transform="translate(0 5)" fill="none" stroke="#08082b" stroke-linecap="round"
                                          stroke-width="1"/>
                                </g>
                            </g>
                        </svg>

                        :

                        <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
                             viewBox="0 0 11.414 6.414">
                            <g id="Group_22451" data-name="Group 22451" transform="translate(10.707 0.707) rotate(90)">
                                <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none" stroke="#08082b"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_12381" data-name="Line 12381" y1="5" x2="5" transform="translate(0 5)"
                                      fill="none" stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>
                    }

                </components.DropdownIndicator>
            )
        );
    };

    useEffect(() => {
        if (!categories) return; // Check if categories is defined

        const urlParams = new URLSearchParams(window.location.search);
        const categoryParam = urlParams.get('category');
        if (categoryParam) {
            const foundCategory = categories.find(category => category.slug === categoryParam);
            if (foundCategory) {
                handleCat(foundCategory.id, foundCategory.slug);
            }
        }
    }, [categories, handleCat]);

    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        // Filter products based on the active category
        const filtered = products?.filter(product => {
            return activeCat === 0 || product.product_data.category_id === activeCat;
        });
        setFilteredProducts(filtered);
    }, [products, activeCat]);

    return (
        <StyledComponent className={"list pt-100"}>
            <Container>
                <div className="list__slider">
                    <ul>
                        <Swiper loop={false}
                                spaceBetween={30}
                                slidesPerView={2}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                                allowTouchMove={true}
                                speed={500}
                                navigation={{
                                    prevEl: '.feature-project .slider_prev',
                                    nextEl: '.feature-project .slider_next',
                                }}
                                modules={[Navigation, EffectFade, Autoplay]}
                                breakpoints={{
                                    1024: {
                                        slidesPerView: 6
                                    },
                                    550: {
                                        slidesPerView: 3
                                    }
                                }}

                        >
                            <SwiperSlide>
                                <li onClick={() => handleCat(0)} className={activeCat === 0 ? 'active' : null}>
                                    <img src="/images/static/all.svg"
                                         alt="all"/> All
                                </li>
                            </SwiperSlide>
                            {
                                categories?.map((item) => {
                                    return (
                                        <SwiperSlide key={item?.id}>
                                            <li key={item?.id} onClick={() => handleCat(item?.id, item?.slug)}
                                                className={activeCat === item?.id ? 'active' : null}><img
                                                src={item?.images?.list?.[1]?.full_path} alt="Lingerie"/> {item?.title}
                                            </li>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </ul>
                </div>

                <Row className={'list__wrap'}>
                    {
                        filteredProducts?.length > 0 ? filteredProducts?.map((item) => {
                                return (
                                    <Col sm={3} key={item.product_data.slug}>
                                        <SingleProduct cat={item?.product_data?.category_title}
                                                       title={item?.product_data?.title}
                                                       img={item?.images?.list?.[0]?.full_path}
                                                       slug={`/product/${item?.product_data?.slug}`}
                                        />
                                    </Col>
                                )
                            }) :
                            <Col>
                                {!loading && <p>No product found</p>}
                            </Col>
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-bottom: 50px;

  .list__slider {
    ul {
      width: 100%;
      margin-bottom: 5px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #F1EFEA;
        left: 0;
        bottom: 1px;
      }

      li {
        font-size: 20px;
        line-height: 24px;
        color: #B7B4B0;
        font-weight: 500;
        font-family: ${textMid};
        cursor: pointer;
        position: relative;
        padding-bottom: 18px;
        display: flex;
        align-items: center;
        height: 100%;

        img {
          margin-right: 15px;
          height: 40px;
        }

        &.active {
          img {
            filter: invert(120%) sepia(7%) saturate(641%) hue-rotate(332deg) brightness(60%) contrast(50%);
          }

          color: ${text};

          &:before {
            position: absolute;
            height: 2px;
            width: 100%;
            left: 0;
            bottom: -4px;
            content: '';
            background-color: ${text};;
          }
        }
      }
    }

    .swiper-slide {
      padding-bottom: 5px;
    }
  }

  .list__wrap {
    padding-top: 60px;
    min-height: 50vh;

    .col-sm-3 {
      margin-bottom: 60px;
      @media (max-width: 575px) {
        margin-bottom: 40px;
      }
    }
  }
`;

export default MyComponent;
