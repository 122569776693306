import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchContact} from "../../api/redux/contact";
import AddressTab from "../../components/contact/AddressTab";
import ContactForm from "../../components/contact/ContactForm";
import InnerBanner from "../../components/InnerBanner";
import {SplitUp} from "../../components/animations/TextAnimation";
import {ParallaxImage, ParallaxImg, ParallaxItem} from "../../components/animations/Parallax";

const MyComponent = () => {
    const dispatch = useDispatch();
    SplitUp()
    ParallaxImg()
    ParallaxImage()
    ParallaxItem()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "contact",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchContact([api_url, api_params]));
    }, []);

    const getData = useSelector((state) => state.contact);
    const banner = getData?.posts?.data;
    const address = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "address");

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Contact | Triple Apparel Limited</title>
                <meta
                    content="Triple Apparels Ltd is the latest addition to the Partex Star family, a household name in Bangladesh. We are well poised to position ourselves as one of the largest global apparel manufacturers with world-class capabilities and standards."
                    name="description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    text={"For inquiries, collaborations, or any other matter, our dedicated team is here to assist you."}
                    title={"Contact"} img={'/images/dynamic/contact-banner.jpg'}/>
                <AddressTab data={address}/>
                <ContactForm/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
