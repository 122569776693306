import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {gsap} from "gsap";
import ReactHtmlParser from "react-html-parser";
import {textMid} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {
    let component = useRef();

    useEffect(() => {
        if (component && window.innerWidth > 768) {
            ScrollTrigger.create({
                trigger: ".equipment-detail__sidebar",
                pin: ".equipment-detail__sidebar",
                start: "top 100",
                end: "+=500",
            });
        }

    }, [data])


    return (
        <StyledComponent className='equipment-detail pt-160 pb-160'>
            <Container>
                <Row>
                    <Col sm={4} className="equipment-detail__sidebar">
                        <img src={data?.images?.list?.[0]?.full_path} alt="/images/dynamic/detail.jpg"/>
                        <p>{data?.product_data?.category_title}</p>
                        <h3>{data?.product_data?.title}</h3>
                    </Col>

                    <Col sm={{span: 7, offset: 1}} className={"equipment-detail__desc"}>
                        {ReactHtmlParser(data?.product_data?.description)}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .equipment-detail__sidebar {
    img {
      margin-bottom: 25px;
      max-width: 100%;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      color: #b7b4b0;
      margin-bottom: 5px;
    }

    h3 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      font-family: ${textMid};
    }
  }

  .equipment-detail__desc {
    p {
      margin-bottom: 20px;
    }

    img {
      margin-bottom: 40px;
      margin-top: 30px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 40px;
    padding-bottom: 0;
    .equipment-detail__sidebar {
      min-width: 100%;
    }

    .equipment-detail__desc {
      min-width: 100%;
      margin: 40px 0 0 0;
    }
  }
`;


export default MyComponent;
