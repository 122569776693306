import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "./Img";
import {textMid} from "../styles/globalStyleVars";

const MyComponent = ({img, imgSm, cat, text, link}) => {
    return (
        <StyledComponent className={'equipment'}>
            <Link to={link}/>
            <div className="equipment__img">
                <Img transition srcSm={imgSm ? imgSm : img} src={img}/>
            </div>
            <div className="equipment__content">
                <p>{cat}</p>
                <h3>{text}</h3>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  width: 100%;
  overflow: hidden;

  a {
    position: absolute;
    inset: 0;
    z-index: 2;
  }

  .equipment__img {
    padding-top: 100%;
    position: relative;
    overflow: hidden;
  }

  .equipment__content {
    margin-top: 20px;

    p {
      font-size: 16px;
      line-height: 20px;
      color: #b7b4b0;
      margin-bottom: 5px;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      font-family: ${textMid};
      line-height: 24px;

    }
  }

  &:hover {
    .equipment__img img {
      transform: scale(1.04);
    }
  }
`;

export default MyComponent;
