import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {gsap} from "gsap";
import {textMid} from "../../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";


const MyComponent = ({data}) => {
    let component = useRef();

    console.log('data', data)

    useEffect(() => {

        const boxes = gsap.utils.toArray('.equipment-detail__sidebar');

        let ctx = gsap.context(() => {
            gsap.to(boxes[0], {
                scrollTrigger: {
                    trigger: boxes[0],
                    start: "top 100",
                    end: 'bottom +350',
                    pin: true,
                    pinSpacing: false
                }
            });
        }, component); // <- selector scoping
        
        return () => ctx.revert();


        // if (data?.images?.list?.length > 0 && window.innerWidth > 768) {
        //     ScrollTrigger.create({
        //         trigger: ".equipment-detail__sidebar",
        //         pin: ".equipment-detail__sidebar",
        //         start: "top 100",
        //         end: "+=500",
        //         markers: true
        //     });
        // }

    }, [data])

    return (
        <StyledComponent className='equipment-detail pt-100 pb-100' ref={component}>
            <Container>
                <Row>
                    <Col sm={4} className="equipment-detail__sidebar">
                        <img
                            src={data?.images?.list?.find(f => f?.["Is Left"] == 'on')?.full_path || "/images/dynamic/eq1.jpg"}
                            alt="/images/dynamic/eq1.jpg"/>
                        <p>Bonding Technology</p>
                        <h3>{data?.data?.title}</h3>
                    </Col>

                    <Col sm={{span: 7, offset: 1}} className={"equipment-detail__desc"}>
                        {ReactHtmlParser(data?.data?.body)}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .equipment-detail__sidebar {
        img {
            margin-bottom: 25px;
            max-width: 100%;
        }

        p {
            font-size: 16px;
            line-height: 20px;
            color: #b7b4b0;
            margin-bottom: 5px;
        }

        h3 {
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
            font-family: ${textMid};

        }
    }

    .equipment-detail__desc {
        p {
            margin-bottom: 20px;
        }

        img {
            margin-bottom: 40px;
            margin-top: 30px;
        }
    }

    @media (max-width: 768px) {
        margin-top: 40px !important;
        padding-bottom: 0px !important;
        .equipment-detail__sidebar {
            min-width: 100%;
        }

        .equipment-detail__desc {
            min-width: 100%;
            margin: 40px 0 0 0;
        }
    }
`;


export default MyComponent;
