import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import TeamSingle from "../TeamSingle";
import Title from "../Title";
import SimpleBar from "simplebar-react";
import 'simplebar/dist/simplebar.min.css';
import {White} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    const [show, setShow] = useState(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState(null);
    const handleClose = () => {
        setShow(false);
        setSelectedTeamMember(null); // Clear the selected team member data when closing the modal
    };
    const handleShow = (teamMember) => {
        setSelectedTeamMember(teamMember); // Set the selected team member data
        setShow(true);
    };

    const handleTeamMemberClick = (e) => {
        handleShow(); // Open the modal
        // You can do something with the 'e' data here, if needed
    };

    const [offset, setOffset] = useState(0)

    return (
        <StyledComponent className={'team-list pt-200 pb-160'}>
            <Container>
                <Title fontSize={'32'} lineHeight={'34'} color={White} margin={'0 0 60px 0'}
                       text={data?.section_data?.subtitle}/>
                <Row>
                    {
                        data?.posts?.list && data?.posts?.list?.length > 0 &&
                        data?.posts?.list?.map((e, i) => {
                            return (
                                <Col sm={4} md={3} onClick={() => handleShow(e)} key={i}>
                                    <TeamSingle title={e?.data?.title} deg={e?.data?.subtitle}
                                                img={e?.images?.[0]?.full_path}/>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>

            <Modal className={'team-modal'} show={show} onHide={handleClose}>
                <div className="team-modal__close" onClick={handleClose}>

                    <img src="/images/static/close.svg" alt="close"/>
                </div>

                <SimpleBar style={{maxHeight: window.innerHeight - 150 + 'px'}}>
                    <div className="team-modal__content">
                        <img src={selectedTeamMember?.images?.[0]?.full_path} alt=""/>
                        <h4>{selectedTeamMember?.data?.title}</h4>
                        <h5>{selectedTeamMember?.data?.subtitle}</h5>

                        <p>
                            {reactHtmlParser(selectedTeamMember?.data?.description)}
                        </p>


                    </div>
                </SimpleBar>

            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #3E3937;

  .col-sm-4 {
    margin-bottom: 60px;
  }
`;

export default MyComponent;
