import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import Button from "../Button";
import SingleProduct from "../SingleProduct";
import SingleMedia from "../SingleMedia";
import {White} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={"related pb-200 pt-200"}>
            <Container>

                <Row>
                    <Col sm={12} className={"d-flex justify-content-between flex-wrap"}>
                        <Title marginSm={"0 0 30px 0"} margin={"0 0 60px 0"} fontSize={"32"} lineHeight={"36"}
                               text={"Related articles"}/>
                        <Button marginSm={'0px 0 40px 0'} text={"view all"} src={"/media"}/>
                    </Col>
                </Row>
                <Row>

                    <Col sm={4}>
                        <SingleMedia title={"Unveiling the secrets of heritage knitting"} date={"19"} year={"2020"}
                                     month={"August"} link={'/media/detail'} type={"News"}
                                     img={'/images/dynamic/news.jpg'}/>
                    </Col>
                    <Col sm={4}>
                        <SingleMedia title={"Unveiling the secrets of heritage knitting"} date={"19"} year={"2020"}
                                     month={"August"} link={'/media/detail'} type={"News"}
                                     img={'/images/dynamic/news.jpg'}/>
                    </Col>
                    <Col sm={4}>
                        <SingleMedia title={"Unveiling the secrets of heritage knitting"} date={"19"} year={"2020"}
                                     month={"August"} link={'/media/detail'} type={"News"}
                                     img={'/images/dynamic/news.jpg'}/>
                    </Col>


                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F1EEE9;

  .blog-single__inner .noise {
    background-color: #F1EEE9;
  }


  .col-sm-4 {
    &:nth-child(n+4) {
      display: none;
    }

    &:nth-child(1) {
      .blog-single__inner__content:after {
        height: 0;
      }

      .noise {
        height: 0;
      }

      .blog-single__inner__content__top {
        h4 {
          //opacity: 0;
          //transform: translateY(-20px);
          color: ${White};;
        }

        p {
          transform: none;
          //opacity: 1;
        }
      }

      .blog-single__inner__content__bottom {
        border-color: ${White};

        h2 {
          color: ${White};
        }

        p {
          color: ${White};

          span {
            color: ${White};
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .col-sm-4 {
      min-width: 50%;

      &:nth-of-type(n+3) {
        display: none;
      }
    }
  }

  @media (max-width: 575px) {
    .title {
      min-width: 100%;
    }

    .col-sm-4 {
      &:nth-of-type(1) {
        margin-bottom: 30px;
      }
    }
  }

`;

export default MyComponent;
